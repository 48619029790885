// 迎检管理
export default {
  path: 'inspect-manage',
  name: 'inspect-manage',
  component: () => import('@/views/inspect-manage/index.vue'),
  meta: {
    locale: '迎检管理',
    icon: 'icon-tool',
  },
  children: [
    {
      path: 'inspect-data-index',
      name: 'inspect-data-index',
      component: () => import('@/views/inspect-manage/inspect-data/index.vue'),
      meta: {
        locale: '迎检数据',
        requiresAuth: true,
        title: '迎检数据',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 迎检数据(详情)
    {
      path: 'inspect_data_add_edit',
      name: 'inspect_data_add_edit',
      component: () => import('@/views/inspect-manage/inspect-data/inspectAddEdit.vue'),
      meta: {
        locale: '迎检管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 迎检管理
    {
      path: 'inspect-manage-index',
      name: 'inspect-manage-index',
      component: () => import('@/views/inspect-manage/inspectManage/index.vue'),
      meta: {
        locale: '迎检管理',
        requiresAuth: true,
        title: '迎检管理',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 迎检管理(详情)
    {
      path: 'inspect_manage_add_change',
      name: 'inspect_manage_add_change',
      component: () => import('@/views/inspect-manage/inspectManage/inspectAddChange.vue'),
      meta: {
        locale: '迎检管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 分类管理
    {
      path: 'class-manage',
      name: 'class-manage',
      component: () => import('@/views/inspect-manage/class-manage/index.vue'),
      meta: {
        locale: '分类管理',
        requiresAuth: true,
        title: '分类管理',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
  ],
}
