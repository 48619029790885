import { createApp } from 'vue'
import ArcoVue from '@arco-design/web-vue'
import ArcoVueIcon from '@arco-design/web-vue/es/icon'
import globalComponents from '@/components'
// import VMdEditor from '@kangc/v-md-editor'
// import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
// import hljs from 'highlight.js'
import VueMarkdownEditor from '@kangc/v-md-editor'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import Prism from 'prismjs'
import router from './router'
import '@/api/interceptor'
import store from './store'
import i18n from './locale'
import directive from './directive'
// import './mock';
import App from './App.vue'
import '@arco-design/web-vue/dist/arco.css'
import '@/assets/style/global.less'

import 'virtual:uno.css'

import '@kangc/v-md-editor/lib/style/base-editor.css'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

VueMarkdownEditor.use(vuepressTheme, {
  Prism,
})

// VMdEditor.use(githubTheme, {
//   Hljs: hljs,
// })

const app = createApp(App)

app.use(ArcoVue, {})
app.use(ArcoVueIcon)

app.use(router)
app.use(store)
app.use(i18n)
app.use(globalComponents)
app.use(directive)

app.use(VueMarkdownEditor)
// app.use(VMdEditor)

app.mount('#app')
