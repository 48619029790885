import axios from 'axios'
import qs from 'query-string'
import { getExpiresToken } from '@/utils/auth'
// import { UserState } from '@/store/modules/user/types';
// 测试-后缀
// export const baseSuffix = '/api/v1';
// export const baseOauthSuffix = '/oauth';
// 云上-后缀
// export const baseSuffix = '/api/v2';
// export const baseOauthSuffix = '/v2/oauth';

const isPROD = import.meta.env.PROD
export const baseSuffix = isPROD ? '/api/v2' : '/api/v1'
export const baseOauthSuffix = isPROD ? '/v2/oauth' : '/oauth'

export interface PhoneData {
  mobile: string
}

export interface LoginData {
  username: string
  password: string
  id: string
  code: string
}

export interface LoginRes {
  token: string
}

export interface LoginCode {
  code: string
}

export function getmsg(data: PhoneData) {
  return axios.post(`${baseOauthSuffix}/sms`, qs.stringify({ mobile: data }), {
    headers: {
      Authorization: '',
      idOrg: '',
      roleFlag: '',
    },
    hasHeader: true,
  })
}

export function login(data: LoginData) {
  return axios.post(
    `${baseOauthSuffix}/token?scope=read&grant_type=mobile&client_id=test_client&client_secret=test_secret`,
    qs.stringify({ username: data.username, password: data.password }),
    {
      headers: {
        Authorization: '',
        idOrg: '',
        roleFlag: '',
      },
      hasHeader: true,
    },
  )
}

export function logout() {
  return axios.post<LoginRes>('/api/user/logout')
}

export function getUserInfo() {
  return axios.post('/api/user/info', {})
}

export function scanLogin(code: LoginCode) {
  return axios.get(`${baseOauthSuffix}/qrcode/qywx/login?code=${code}`, {
    headers: {
      Authorization: '',
      idOrg: '',
      roleFlag: '',
    },
    hasHeader: true,
  })
}

export function getUserInfoList() {
  return axios.get(`${baseSuffix}/user/info?role=P1`, {
    headers: {
      Authorization: `Bearer ${getExpiresToken('ExpiresToken')}`,
      idOrg: '',
      roleFlag: '',
    },
    hasHeader: true,
  })
}

// 获取图片验证码
export function captureKaptcha() {
  return axios.get(`${baseOauthSuffix}/login/kaptcha`, {
    headers: {
      Authorization: '',
      idOrg: '',
      roleFlag: '',
    },
    hasHeader: true,
  })
}
// 登录接口---2022/10
export function oauthLogin(data: any) {
  return axios.post(
    `${baseOauthSuffix}/login`,
    {
      username: data.username,
      password: data.password,
      grantType: 'mobile',
      clientId: 'test_client',
      clientSecret: 'test_secret',
      id: data.id,
      code: data.code,
    },
    {
      headers: {
        Authorization: '',
        idOrg: '',
        roleFlag: '',
      },
      hasHeader: true,
    },
  )
}
