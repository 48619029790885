export default {
  path: 'accountManage',
  name: 'accountManage',
  component: () => import('@/views/account-manage/index.vue'),
  meta: {
    locale: '系统管理',
    icon: 'icon-settings',
  },
  children: [
    {
      path: 'department-manage',
      name: 'department-manage',
      component: () => import('@/views/account-manage/department-manage/index.vue'),
      meta: {
        locale: '部门管理',
      },
    },
    {
      path: 'staff-manage',
      name: 'staff-manage',
      component: () => import('@/views/account-manage/staff-manage/index.vue'),
      meta: {
        locale: '人员管理',
      },
    },
    {
      path: 'role-manage',
      name: 'role-manage',
      component: () => import('@/views/account-manage/role-manage/index.vue'),
      meta: {
        locale: '角色管理',
      },
    },
    // {
    //   path: 'unit-manage',
    //   name: 'unit-manage',
    //   component: () => import('@/views/account-manage/unit-manage/index.vue'),
    //   meta: {
    //     locale: '单位管理',
    //   },
    // },
    {
      path: 'reminder-time',
      name: 'reminder-time',
      component: () => import('@/views/account-manage/reminder-time/index.vue'),
      meta: {
        locale: '自定义提醒时间',
      },
    },
    // {
    //   path: 'system-config',
    //   name: 'system-config',
    //   component: () => import('@/views/account-manage/system-config/index.vue'),
    //   meta: {
    //     locale: '系统配置',
    //   },
    // },
    // {
    //   path: 'permission-manage',
    //   name: 'permission-manage',
    //   component: () => import('@/views/account-manage/permission-manage/index.vue'),
    //   meta: {
    //     locale: '权限管理',
    //   },
    // },
    {
      path: 'account-manage-apply',
      name: 'account-manage-apply',
      component: () => import('@/views/account-manage/pages/apply/index.vue'),
      meta: {
        locale: '账号权限',
        requiresAuth: true,
        roles: ['R0', 'Rp'],
      },
    },
    {
      path: 'account-manage-verify',
      name: 'account-manage-verify',
      component: () => import('@/views/account-manage/pages/verify/index.vue'),
      meta: {
        locale: '账号审核',
        requiresAuth: true,
        roles: ['Rm'],
      },
    },
    // {
    //   path: 'customer-person-manage',
    //   name: 'customer-person-manage',
    //   component: () => import('@/views/account-manage/pages/personManage/index.vue'),
    //   meta: {
    //     locale: '客户人员管理',
    //     requiresAuth: true,
    //     roles: ['Rm', 'Rp'],
    //   },
    // },
  ],
}
