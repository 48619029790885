export default {
  path: 'service-manage',
  name: 'service-manage',
  component: () => import('@/views/service-manage/index.vue'),
  meta: {
    locale: '服务管理',
    icon: 'icon-shake',
  },
  children: [
    {
      path: 'service-implement',
      name: 'service-implement',
      component: () => import('@/views/service-manage/service-implement/index.vue'),
      meta: {
        locale: '服务实施',
        requiresAuth: true,
        title: '服务实施',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 自运营服务（安全巡检、脆弱性检查、主机扫描）服务确认
    {
      path: 'safe_inspection_confirm',
      name: 'safe_inspection_confirm',
      component: () => import('@/views/service-manage/pages/safe-inspection/index.vue'),
      meta: {
        locale: '日常安全巡检服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 自运营服务（安全巡检、脆弱性检查、主机扫描）服务变更
    {
      path: 'safe_inspection_change',
      name: 'safe_inspection_change',
      component: () => import('@/views/service-manage/pages/safe-inspection/service-change.vue'),
      meta: {
        locale: '日常安全巡检服务变更',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 自运营服务（安全巡检、脆弱性检查、主机扫描）变更审核
    {
      path: 'change_review_index',
      name: 'change_review_index',
      component: () => import('@/views/service-manage/pages/safe-inspection/change-review.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 自运营服务（安全整改加固服务）变更审核
    {
      path: 'change_review_reinforce',
      name: 'change_review_reinforce',
      component: () => import('@/views/service-manage/pages/safe-reinforce/safe-reinforce-review.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 主机扫描-添加结果
    {
      path: 'host_scanning_addResult',
      name: 'host_scanning_addResult',
      component: () => import('@/views/service-manage/pages/host-scanning/add-result.vue'),
      meta: {
        locale: '主机漏洞扫描服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 主机扫描-添加结果-导入记录详情
    {
      path: 'host_records_detail',
      name: 'host_records_detail',
      component: () => import('@/views/service-manage/pages/host-scanning/records-detail.vue'),
      meta: {
        locale: '主机漏洞扫描服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全巡检-添加结果
    {
      path: 'inspection_addResult',
      name: 'inspection_addResult',
      component: () => import('@/views/service-manage/pages/safe-inspection/add-result.vue'),
      meta: {
        locale: '日常安全巡检服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 脆弱性检查-添加结果
    {
      path: 'configure_addResult',
      name: 'configure_addResult',
      component: () => import('@/views/service-manage/pages/configure-check/add-result.vue'),
      meta: {
        locale: '安全配置核查服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 脆弱性检查-添加结果-配置信息详情
    {
      path: 'serviceCheckResult_configure',
      name: 'serviceCheckResult_configure',
      component: () => import('@/views/service-manage/pages/configure-check/components/configure-info-detail.vue'),
      meta: {
        locale: '安全配置核查服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 脆弱性检查-添加结果-导入记录详情
    {
      path: 'configure_recorda_detail',
      name: 'configure_recorda_detail',
      component: () => import('@/views/service-manage/pages/configure-check/components/records-detail.vue'),
      meta: {
        locale: '安全配置核查服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全整改加固服务-添加结果
    {
      path: 'reinforce_addResult',
      name: 'reinforce_addResult',
      component: () => import('@/views/service-manage/pages/safe-reinforce/add-result.vue'),
      meta: {
        locale: '安全配置核查服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全整改加固服务-添加结果-加固策略详情
    {
      path: 'reinforce_detail',
      name: 'reinforce_detail',
      component: () => import('@/views/service-manage/pages/safe-reinforce/components/reinforce-detail.vue'),
      meta: {
        locale: '安全配置核查服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全整改加固服务-服务确认
    {
      path: 'safe_reinforce_confirm',
      name: 'safe_reinforce_confirm',
      component: () => import('@/views/service-manage/pages/safe-reinforce/index.vue'),
      meta: {
        locale: '安全整改加固服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全整改加固服务-服务变更
    {
      path: 'safe_reinforce_change',
      name: 'safe_reinforce_change',
      component: () => import('@/views/service-manage/pages/safe-reinforce/service-change.vue'),
      meta: {
        locale: '安全整改加固服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 远程渗透测试服务-服务确认
    {
      path: 'permeate_test_confirm',
      name: 'permeate_test_confirm',
      component: () => import('@/views/service-manage/pages/penetration-test/index.vue'),
      meta: {
        locale: '远程渗透测试服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 远程渗透测试服务-服务变更
    {
      path: 'permeate_test_change',
      name: 'permeate_test_change',
      component: () => import('@/views/service-manage/pages/penetration-test/service-change.vue'),
      meta: {
        locale: '远程渗透测试服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 远程渗透测试服务-变更审核
    {
      path: 'change_review_permeate_test',
      name: 'change_review_permeate_test',
      component: () => import('@/views/service-manage/pages/penetration-test/change-review.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 远程渗透测试服务-添加结果
    {
      path: 'permeate_test_addResult',
      name: 'permeate_test_addResult',
      component: () => import('@/views/service-manage/pages/penetration-test/add-result.vue'),
      meta: {
        locale: '远程渗透测试服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 远程渗透测试服务（取URL地址）-添加结果
    {
      path: 'pTest_addResult',
      name: 'pTest_addResult',
      component: () => import('@/views/service-manage/pages/penetration-test/components/pTest_addResult.vue'),
      meta: {
        locale: '远程渗透测试服务添加结果',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 代码安全审计服务-服务确认
    {
      path: 'code_audit_confirm',
      name: 'code_audit_confirm',
      component: () => import('@/views/service-manage/pages/codeAudit/index.vue'),
      meta: {
        locale: '代码安全审计服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 代码安全审计服务-服务变更
    {
      path: 'code_audit_change',
      name: 'code_audit_change',
      component: () => import('@/views/service-manage/pages/codeAudit/service-change.vue'),
      meta: {
        locale: '代码安全审计服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 代码安全审计服务-添加结果
    {
      path: 'code_audit_addResult',
      name: 'code_audit_addResult',
      component: () => import('@/views/service-manage/pages/codeAudit/add-result.vue'),
      meta: {
        locale: '代码安全审计服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 添加结果-代码安全审计服务（取系统名称）
    {
      path: 'code_audit_result',
      name: 'code_audit_result',
      component: () => import('@/views/service-manage/pages/codeAudit/components/code_audit_result.vue'),
      meta: {
        locale: '代码安全审计服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 应用漏洞扫描服务-服务确认
    {
      path: 'website_scanning_confirm',
      name: 'website_scanning_confirm',
      component: () => import('@/views/service-manage/pages/websites-canning/index.vue'),
      meta: {
        locale: '应用漏洞扫描服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 应用漏洞扫描服务-服务变更
    {
      path: 'website_scanning_change',
      name: 'website_scanning_change',
      component: () => import('@/views/service-manage/pages/websites-canning/service-change.vue'),
      meta: {
        locale: '应用漏洞扫描服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 应用漏洞扫描服务-变更审核
    {
      path: 'change_review_webScanning',
      name: 'change_review_webScanning',
      component: () => import('@/views/service-manage/pages/websites-canning/change-review.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 应用漏洞扫描服务-添加结果
    {
      path: 'website_scanning_addResult',
      name: 'website_scanning_addResult',
      component: () => import('@/views/service-manage/pages/host-scanning/add-result.vue'),
      meta: {
        locale: '应用漏洞扫描服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 网站安全监控服务-服务确认
    {
      path: 'website_monitor_confirm',
      name: 'website_monitor_confirm',
      component: () => import('@/views/service-manage/pages/website-monitor/index.vue'),
      meta: {
        locale: '网站安全监控服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 网站安全监控服务-服务变更
    {
      path: 'website_monitor_change',
      name: 'website_monitor_change',
      component: () => import('@/views/service-manage/pages/website-monitor/service-change.vue'),
      meta: {
        locale: '网站安全监控服务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 互联网暴露面监测-服务确认
    {
      path: 'Internet_monitor_confirm',
      name: 'Internet_monitor_confirm',
      component: () => import('@/views/service-manage/pages/Internet-monitor/index.vue'),
      meta: {
        locale: '互联网暴露面监测',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 应急演练、等保差距分析、驻场安全运维服务、信息安全自查服务-添加结果
    {
      path: 'first_service_result',
      name: 'first_service_result',
      component: () => import('@/views/service-manage/pages/first-service-result/index.vue'),
      meta: {
        // locale: '安全巡检服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 特殊时期现场值守、安全设备维保、网络结构优化部署-添加结果
    {
      path: 'second_service_result',
      name: 'second_service_result',
      component: () => import('@/views/service-manage/pages/second-service-result/index.vue'),
      meta: {
        // locale: '安全巡检服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全咨询
    {
      path: 'safe_consult',
      name: 'safe_consult',
      component: () => import('@/views/service-manage/pages/safe-consult/index.vue'),
      meta: {
        // locale: '安全巡检服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 应急响应
    {
      path: 'safe_response',
      name: 'safe_response',
      component: () => import('@/views/service-manage/pages/safe-response/index.vue'),
      meta: {
        // locale: '安全巡检服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全培训
    {
      path: 'safe_train',
      name: 'safe_train',
      component: () => import('@/views/service-manage/pages/safe-train/index.vue'),
      meta: {
        // locale: '安全巡检服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    /* 应急响应处置服务11、安全应急演练服务12、等保集成整改服务13、网络安全规划服务15、安全管理体系咨询16、信息安全自查服务17、
     安全策略优化服务21、等级保护合规咨询22、数据安全体系咨询23、安全应急预案服务25、风险评估咨询服务26、攻击溯源分析服务27、
     专项整改优化服务28、密码评估整改服务31、备份恢复演练服务32、数据恢复演练服务33、应用恢复演练服务34、安全运行监测服务35、
     移动安全检测服务36、红队攻击检测服务37、红蓝对抗演练服务38、密码应用合规咨询39、综合安全保障服务40 */
    // 添加结果(应急响应处置)
    {
      path: 'response-disposal',
      name: 'response-disposal',
      component: () => import('@/views/service-manage/pages/other-service/response-disposal/index.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    //添加结果(重要时期保障服务、驻场运维保障服务)
    {
      path: 'safe_service',
      name: 'safe_service',
      component: () => import('@/views/service-manage/pages/other-service/safe-service/index.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    //添加结果(安全技能培训服务、安全意识培训服务)
    {
      path: 'train_service',
      name: 'train_service',
      component: () => import('@/views/service-manage/pages/other-service/train-service/index.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全巡检 - 服务结果
    {
      path: 'serviceCheckResult_inspection',
      name: 'serviceCheckResult_inspection',
      component: () => import('@/views/service-manage/pages/safe-inspection/serviceCheckResult_inspection.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全巡检-详情
    {
      path: 'sam_winResultDetail_inspection',
      name: 'sam_winResultDetail_inspection',
      component: () =>
        import('@/views/service-manage/pages/safe-inspection/inspection_detail/sam_resultDetail_inspection.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全巡检(LINUX信息详情)-详情
    {
      path: 'sam_linuxResultDetails_inspection',
      name: 'sam_linuxResultDetails_inspection',
      component: () =>
        import('@/views/service-manage/pages/safe-inspection/inspection_detail/sam_linuxResultDetail_inspection.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 脆弱性检查 - 结果查看
    {
      path: 'serviceCheckResult_configure',
      name: 'serviceCheckResult_configure',
      component: () => import('@/views/service-manage/pages/configure-check/serviceCheckResult_configure.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 主机扫描 -结果查看
    {
      path: 'sam_serviceCheckResult_host',
      name: 'sam_serviceCheckResult_host',
      component: () => import('@/views/service-manage/pages/host-scanning/serviceCheckResult_host.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 应用漏洞扫描服务 -结果查看
    {
      path: 'sam_serviceCheckResult_web_scanning',
      name: 'sam_serviceCheckResult_web_scanning',
      component: () => import('@/views/service-manage/pages/websites-canning/serviceCheckResult_scanning.vue'),
      meta: {
        locale: '服务实施',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全整改加固服务 - 服务实施情况
    {
      path: 'sam_serviceImplement_safe_reinforce',
      name: 'sam_serviceImplement_safe_reinforce',
      component: () => import('@/views/service-manage/pages/safe-reinforce/sam_serviceImplement_safety_reinforce.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全整改加固服务 - 加固策略
    {
      path: 'sam_safe_reinforce_checkResult',
      name: 'sam_safe_reinforce_checkResult',
      component: () => import('@/views/service-manage/pages/safe-reinforce/sam_safety_reinforce_checkResult.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 远程渗透测试服务 - 第一层
    {
      path: 'sam_serviceImplement_test',
      name: 'sam_serviceImplement_test',
      component: () => import('@/views/service-manage/pages/penetration-test/sam_serviceImplement_ptest.vue'),
      meta: {
        locale: '服务实施',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 远程渗透测试服务 - 第二层
    {
      path: 'sam_serviceCheckResult_test',
      name: 'sam_serviceCheckResult_test',
      component: () => import('@/views/service-manage/pages/penetration-test/sam_serviceCheckResult_ptest.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 远程渗透测试服务 - 第二层
    {
      path: 'sam_appServiceCheckResult_test',
      name: 'sam_appServiceCheckResult_test',
      component: () => import('@/views/service-manage/pages/penetration-test/sam_appServiceCheckResult_ptest.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 代码安全审计服务 - 第一层
    {
      path: 'serviceImplement_codeAudit',
      name: 'serviceImplement_codeAudit',
      component: () => import('@/views/service-manage/pages/codeAudit/sam_serviceImplement_codeAudit.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 代码安全审计服务 - 第二层
    {
      path: 'serviceCheckResult_codeAudit',
      name: 'serviceCheckResult_codeAudit',
      component: () => import('@/views/service-manage/pages/codeAudit/sam_serviceCheckResult_codeAudit.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 网站安全监控服务 - 结果查看
    {
      path: 'resultLook_websiteMonitor',
      name: 'resultLook_websiteMonitor',
      component: () => import('@/views/service-manage/pages/website-monitor/resultLook_websiteMonitor.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 主机扫描、脆弱性检查-报告下载页面
    {
      path: 'service_report_download',
      name: 'service_report_download',
      component: () => import('@/views/service-manage/pages/host-scanning/report-download.vue'),
      meta: {
        locale: '服务实施',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 报告下载 --- 结束
    {
      path: 'report_download',
      name: 'report_download',
      component: () => import('@/views/mywork/report-download/index.vue'),
      meta: {
        locale: '报告下载',
        title: '报告下载',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 应用日志分析服务确认
    {
      path: 'safe_log_analysis_confirm',
      name: 'safe_log_analysis_confirm',
      component: () => import('@/views/service-manage/pages/safe-analysis/index.vue'),
      meta: {
        locale: '应用日志分析服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全日志分析结果查看
    {
      path: 'safe_analysis_result',
      name: 'safe_analysis_result',
      component: () => import('@/views/service-manage/pages/safe-analysis/safe-analysis-result.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 分析结果
    {
      path: 'analysis_result',
      name: 'analysis_result',
      component: () => import('@/views/service-manage/pages/safe-analysis/analysis-result.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 主机日志分析服务确认
    {
      path: 'host_log_analysis_confirm',
      name: 'host_log_analysis_confirm',
      component: () => import('@/views/service-manage/pages/host-analysis/index.vue'),
      meta: {
        locale: '主机日志分析服务确认',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 主机日志分析结果查看
    {
      path: 'host_analysis_result_view',
      name: 'host_analysis_result_view',
      component: () => import('@/views/service-manage/pages/host-analysis/host-analysis-result.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 主机分析结果(windows)
    {
      path: 'host_analysis_result',
      name: 'host_analysis_result',
      component: () => import('@/views/service-manage/pages/host-analysis/analysis-result.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 主机分析结果(linux)
    {
      path: 'host_analysis_result_linux',
      name: 'host_analysis_result_linux',
      component: () => import('@/views/service-manage/pages/host-analysis/analysis-result-linux.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 攻防实验室临时服务服务确认
    {
      path: 'defense_temp_confirm',
      name: 'defense_temp_confirm',
      component: () => import('@/views/service-manage/pages/defense-temp-service/index.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 攻防实验室临时服务结果查看
    {
      path: 'defense_temp_result_view',
      name: 'defense_temp_result_view',
      component: () => import('@/views/service-manage/pages/defense-temp-service/defense-result-view.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 资产暴露查询服务
    {
      path: 'asset_expose_confirm',
      name: 'asset_expose_confirm',
      component: () => import('@/views/service-manage/pages/asset-expose/index.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 资产暴露查询服务结果查看
    {
      path: 'asset_expose_result_view',
      name: 'asset_expose_result_view',
      component: () => import('@/views/service-manage/pages/asset-expose/asset-expose-result.vue'),
      meta: {
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
  ],
}
