<template>
  <a-config-provider :locale="locale">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <global-setting />
  </a-config-provider>
</template>


<script lang="ts">
import { defineComponent, computed } from 'vue'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn'
import GlobalSetting from '@/components/global-setting/index.vue'
import useLocale from '@/hooks/locale'

export default defineComponent({
  components: {
    GlobalSetting,
  },
  setup() {
    const { currentLocale } = useLocale()
    const locale = computed(() => {
      switch (currentLocale.value) {
        case 'zh-CN':
          return zhCN
        case 'en-US':
          return enUS
        default:
          return enUS
      }
    })
    return {
      locale,
    }
  },
})
</script>
