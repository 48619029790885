export default {
  path: 'data-zone',
  name: 'data-zone',
  component: () => import('@/views/data-zone/index.vue'),
  meta: {
    locale: '数据专区',
    icon: 'icon-layers',
  },
  children: [
    {
      path: 'data-z-data-overview',
      name: 'data-z-data-overview',
      component: () => import('@/views/data-zone/pages/data-overview/index.vue'),
      meta: {
        locale: '数据概览',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'data-z-system-operation',
      name: 'data-z-system-operation',
      component: () => import('@/views/data-zone/pages/system-operation/index.vue'),
      meta: {
        locale: '系统运行情况',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'data-z-c-system-host-resources',
      name: 'data-z-c-system-host-resources',
      component: () => import('@/views/data-zone/pages/c-system-host-resources/index.vue'),
      meta: {
        locale: '云系统主机资源',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'usageIndex',
      name: 'usageIndex',
      component: () => import('@/views/data-zone/pages/c-system-host-resources/component/UsageIndex.vue'),
      meta: {
        locale: '云系统主机资源使用情况',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        keepAlive: true,
      },
    },
    {
      path: 'host-performance',
      name: 'host-performance',
      component: () => import('@/views/data-zone/pages/c-system-host-resources/component/host-performance.vue'),
      meta: {
        locale: '云主机性能',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        keepAlive: true,
      },
    },
    {
      path: 'data-z-VPN-log-service-data',
      name: 'data-z-VPN-log-service-data',
      component: () => import('@/views/data-zone/pages/VPN-log-service-data/index.vue'),
      meta: {
        locale: 'VPN日志服务数据',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'data-z-WAF-service-data',
      name: 'data-z-WAF-service-data',
      component: () => import('@/views/data-zone/pages/WAF-service-data/index.vue'),
      meta: {
        locale: 'WAF服务日志数据',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'data-z-r-access-service-log-data',
      name: 'data-z-r-access-service-log-data',
      component: () => import('@/views/data-zone/pages/r-access-service-log-data/index.vue'),
      meta: {
        locale: '远程接入服务日志数据',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'data-z-attack-log',
      name: 'data-z-attack-log',
      component: () => import('@/views/data-zone/pages/attack-log/index.vue'),
      meta: {
        locale: '攻击日志',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
  ],
}
