export default {
  path: 'project-manage',
  name: 'project-manage',
  component: () => import('@/views/project-manage/index.vue'),
  meta: {
    locale: '项目管理',
    icon: 'icon-storage',
  },
  children: [
    {
      path: 'project-manage-index',
      name: 'project-manage-index',
      component: () => import('@/views/project-manage/projectManage/index.vue'),
      meta: {
        locale: '项目管理',
        requiresAuth: true,
        title: '项目管理',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 项目管理(详情)
    {
      path: 'project_manage_add_change',
      name: 'project_manage_add_change',
      component: () => import('@/views/project-manage/projectManage/projectAddChange.vue'),
      meta: {
        locale: '项目管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 审核
    {
      path: 'project_manage_review',
      name: 'project_manage_review',
      component: () => import('@/views/project-manage/projectManage/projectReview.vue'),
      meta: {
        locale: '项目管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 项目看板
    {
      path: 'project-board',
      name: 'project-board',
      component: () => import('@/views/project-manage/project-board/index.vue'),
      meta: {
        locale: '项目看板',
        requiresAuth: true,
        title: '项目看板',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 项目统计-部门经理
    {
      path: 'project-statistics',
      name: 'project-statistics',
      component: () => import('@/views/project-manage/project-statistics/index.vue'),
      meta: {
        locale: '项目统计',
        requiresAuth: true,
        title: '项目统计',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 项目统计-项目经理、项目成员
    {
      path: 'project-count',
      name: 'project-count',
      component: () => import('@/views/project-manage/project-count/index.vue'),
      meta: {
        locale: '项目统计',
        requiresAuth: true,
        title: '项目统计',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'project-approval',
      name: 'project-approval',
      component: () => import('@/views/project-manage/project-approval/index.vue'),
      meta: {
        locale: '项目立项',
        title: '项目立项',
      },
    },
    {
      path: 'project-change',
      name: 'project-change',
      component: () => import('@/views/project-manage/project-change/index.vue'),
      meta: {
        locale: '项目变更',
        title: '项目变更',
      },
    },
    {
      path: 'project-end',
      name: 'project-end',
      component: () => import('@/views/project-manage/end/index.vue'),
      meta: {
        locale: '项目结项',
        title: '项目结项',
        hideInMenu: true,
      },
    },
  ],
}
