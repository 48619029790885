export default {
  path: 'internetDetection',
  name: 'internetDetection',
  component: () => import('@/views/internet-detection/index.vue'),
  meta: {
    locale: '互联网暴露面监测',
    icon: 'icon-code-sandbox',
  },
  children: [
    {
      path: 'internet-detection-monitoring',
      name: 'internet-detection-monitoring',
      component: () => import('@/views/internet-detection/pages/monitoring-tasks/index.vue'),
      meta: {
        locale: '监测任务管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    {
      path: 'i-d-m-add',
      name: 'i-d-m-add',
      component: () => import('@/views/internet-detection/pages/monitoring-tasks/add.vue'),
      meta: {
        locale: '监测任务管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'i-d-m-search-ip',
      name: 'i-d-m-search-ip',
      component: () => import('@/views/internet-detection/pages/monitoring-tasks/search-ip.vue'),
      meta: {
        locale: '监测ip详情',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'i-d-m-search-site',
      name: 'i-d-m-search-site',
      component: () => import('@/views/internet-detection/pages/monitoring-tasks/search-site.vue'),
      meta: {
        locale: '监测site详情',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'i-d-m-ipDetail',
      name: 'i-d-m-ipDetail',
      component: () => import('@/views/internet-detection/pages/monitoring-tasks/components/search-detail/ip-page.vue'),
      meta: {
        locale: 'IP详情',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'i-d-m-domainDetail',
      name: 'i-d-m-domainDetail',
      component: () =>
        import('@/views/internet-detection/pages/monitoring-tasks/components/search-detail/domain-page.vue'),
      meta: {
        locale: '域名详情',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'internet-detection-alarm',
      name: 'internet-detection-alarm',
      component: () => import('@/views/internet-detection/pages/alarm-list/index.vue'),
      meta: {
        locale: '告警列表',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    {
      path: 'internet-detection-alarm-detail',
      name: 'internet-detection-alarm-detail',
      component: () => import('@/views/internet-detection/pages/alarm-list/list-detail.vue'),
      meta: {
        locale: '告警详情',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
  ],
}
