import { defineStore } from 'pinia'

export default defineStore('service', {
  state: () => ({
    idOrg: undefined,
  }),
  actions: {
    setIdorg(idOrg: string) {
      this.idOrg = idOrg
    },
  },
})
