// 威胁感知
export default {
  path: 'threat-percept',
  name: 'threat-percept',
  component: () => import('@/views/threat-percept/index.vue'),
  meta: {
    locale: '威胁感知',
    icon: 'icon-thunderbolt',
  },
  children: [
    {
      path: 'threat-log',
      name: 'threat-log',
      component: () => import('@/views/threat-percept/threat-log/index.vue'),
      meta: {
        locale: '威胁日志',
        title: '威胁日志',
      },
    },
    {
      path: 'threat-analysis',
      name: 'threat-analysis',
      component: () => import('@/views/threat-percept/threat-analysis/index.vue'),
      meta: {
        locale: '威胁分析',
        title: '威胁分析',
      },
    },
    // 威胁事件
    {
      path: 'threat-event',
      name: 'threat-event',
      component: () => import('@/views/threat-percept/threat-event/index.vue'),
      meta: {
        locale: '威胁事件',
        requiresAuth: true,
        title: '威胁事件',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 威胁画像
    {
      path: 'threat-draw',
      name: 'threat-draw',
      component: () => import('@/views/threat-percept/threat-draw/index.vue'),
      meta: {
        locale: '威胁画像',
        requiresAuth: true,
        title: '威胁画像',
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 威胁事件详情
    {
      path: 'threat-event-detail',
      name: 'threat-event-detail',
      component: () => import('@/views/threat-percept/components/threatEventDetail.vue'),
      meta: {
        locale: '威胁事件',
        requiresAuth: true,
        title: '威胁事件',
        hideInMenu: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    // 威胁画像详情
    {
      path: 'threat-draw-detail',
      name: 'threat-draw-detail',
      component: () => import('@/views/threat-percept/threat-draw/threatDrawDetail.vue'),
      meta: {
        locale: '威胁画像',
        requiresAuth: true,
        title: '威胁画像',
        hideInMenu: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
  ],
}
