import localeMessageBox from '@/components/message-box/locale/en-US'
import localeLogin from '@/views/login/locale/en-US'

// 任务管理
import localeWorkOrderManage from '@/views/mywork/work-order-manage/locale/en-US'
import localeOrderMonitoring from '@/views/mywork/order-monitoring/locale/en-US'

// 资产管理
import localeAssetManageOverview from '@/views/asset-manage/overview/locale/en-US'
import localeAssetManageMaintain from '@/views/asset-manage/maintain/locale/en-US'

// 隐患处理
// import localeWebsiteHiddenDanger from '@/views/hidden-danger/website/locale/en-US';
// import localeHostHiddenDanger from '@/views/hidden-danger/host/locale/en-US';

// 综合安全态势
import localeSSituationDashboard from '@/views/security-situation/dashboard/locale/en-US'

// 攻击告警
import localeAWarningAAnalysis from '@/views/attack-warning/alarm-analysis/locale/en-US'
import localeAWarningADetails from '@/views/attack-warning/alarm-details/locale/en-US'
import localeAWarningAThreat from '@/views/attack-warning/threat-analysis/locale/en-US'
import localeAWarningATime from '@/views/attack-warning/time-analysis/locale/en-US'
import localeAWarningAToday from '@/views/attack-warning/today-attack/locale/en-US'

// 重保中心
import localeKeyPointOverview from '@/views/key-point/overview/locale/en-US'
import localeKeyPointAttack from '@/views/key-point/attack/locale/en-US'

import localeSuccess from '@/views/result/success/locale/en-US'
import localeError from '@/views/result/error/locale/en-US'
import localeFresh from '@/views/result/refresh/locale/en-US'

import locale403 from '@/views/exception/403/locale/en-US'
import locale404 from '@/views/exception/404/locale/en-US'
import locale500 from '@/views/exception/500/locale/en-US'

import localeUserInfo from '@/views/user/info/locale/en-US'
import localeUserSetting from '@/views/user/setting/locale/en-US'

import localeSettings from './en-US/settings'

export default {
  'menu.mywork': 'Mywork',
  'menu.result': 'Result',
  'menu.security-situation': 'dashboard',
  'menu.attack-warning': 'attack-warning',
  'menu.key-point': 'key-point',
  'menu.exception': 'Exception',
  'menu.user': 'User Center',
  'navbar.docs': 'Docs',
  'navbar.action.locale': 'Switch to English',
  ...localeSettings,
  ...localeMessageBox,
  ...localeLogin,
  ...localeWorkOrderManage,
  ...localeOrderMonitoring,
  ...localeAssetManageOverview,
  ...localeAssetManageMaintain,
  // ...localeWebsiteHiddenDanger,  // 网站隐患
  // ...localeHostHiddenDanger,   // 主机隐患
  ...localeSSituationDashboard,
  ...localeAWarningAAnalysis,
  ...localeAWarningADetails,
  ...localeAWarningAThreat,
  ...localeAWarningATime,
  ...localeAWarningAToday,
  ...localeKeyPointOverview,
  ...localeKeyPointAttack,
  ...localeSuccess,
  ...localeError,
  ...localeFresh,
  ...locale403,
  ...locale404,
  ...locale500,
  ...localeUserInfo,
  ...localeUserSetting,
}
