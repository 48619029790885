import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { Message, Modal } from '@arco-design/web-vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store'
import { ref } from 'vue'
import { delExpiresToken, getExpiresToken } from '@/utils/auth'

const router = useRouter()
const ifToken = ref(0)
const ifUnitInvalid = ref(0)
const logout = async () => {
  localStorage.clear()
  delExpiresToken('ExpiresToken')
  const userStore = useUserStore()
  await userStore.logout()
  window.location.reload()
}

const unitInvalid = () => {
  ifUnitInvalid.value = 1
  Modal.error({
    title: '确认退出',
    content: '单位列表发生变化，请重新登录',
    okText: '重新登录',
    async onOk() {
      localStorage.clear()
      delExpiresToken('ExpiresToken')
      ifUnitInvalid.value = 0
      const userStore = useUserStore()
      await userStore.logout()
      window.location.reload()
    },
  })
}
export interface HttpResponse<T = unknown> {
  status: number
  message: string
  code: string
  data: T
  access_token: string
}

// apt态势感知 - 专用
export const aptSuffix = '/api/apt'
// export const baseURL = import.meta.env.VITE_API_BASE_URL;
// 数据专区 - 专用
export const dataCenterSuffix = '/api/data-center'
// 内部平台跳转专用
export const jumpCloudSite = 'cmss.axtx.com.cn'
// 本地 + 线上测试
// export const baseURL = '/api';

// 测试-后缀
// export const baseSuffix = '/api/v1';
// export const baseOauthSuffix = '/oauth';

// 线上-云上
// export const baseURL = 'https://cmss.axtx.com.cn';
// 云上-后缀
// export const baseSuffix = '/api/v2';
// export const baseOauthSuffix = '/v2/oauth';

const isPROD = import.meta.env.PROD
export const baseSuffix = isPROD ? '/api/v2' : '/api/v1'
export const baseOauthSuffix = isPROD ? '/v2/oauth' : '/oauth'

// 优化不同环境下动态导入
export const baseURL = import.meta.env.VITE_BASE_URL
// 业务相关
// 1、文件前缀
export const fileSuffix = '/api/file/'

axios.defaults.baseURL = baseURL
axios.defaults.timeout = 180000

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // console.log('config=====>', config);
    // 需要访问第二个接口服务器
    if (config.useApi2) {
      config.baseURL = import.meta.env.VITE_BASE_URL2
    }
    if (config.noHeaders) {
      config.headers = null
    } else if (!config.hasHeader) {
      // console.log(1111,getExpiresToken('ExpiresToken'),localStorage.getItem('currentCompanyId'));
      if (config.specialHeaders) {
        // console.log('config.headers', config.headers);
      } else if (getExpiresToken('ExpiresToken')) {
        config.headers = {
          Authorization: `Bearer ${getExpiresToken('ExpiresToken')}`,
          idOrg: localStorage.getItem('currentCompanyId') || '',
          roleFlag: localStorage.getItem('currentRole') || '',
        }
      }
    }
    return config
  },

  (error) => {
    // do something
    return Promise.reject(error)
  }
)
// // add response interceptors
axios.interceptors.response.use(
  (response) => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== '000000' && res.code) {
      Message.error({
        content: res.message || '获取接口失败',
        duration: 5 * 1000,
      })
      return Promise.reject(new Error(res.message || '获取接口失败'))
    }
    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (ifToken.value === 0) {
            logout()
          }
          break
        case 451:
          if (ifUnitInvalid.value === 0) {
            unitInvalid()
          }
          break
        default:
      }
    }
    Message.error({
      content: error?.response?.data?.message || '获取接口失败',
      duration: 5 * 1000,
    })
    return Promise.reject(error?.response?.data)
  }
)
