export default {
  path: 'work-platform',
  name: 'work-platform',
  component: () => import('@/views/work-platform/index.vue'),
  meta: {
    locale: '我的',
    icon: 'icon-book',
  },
  children: [
    {
      path: 'work-platform-index',
      name: 'work-platform-index',
      component: () => import('@/views/work-platform/pages/workbench/index.vue'),
      meta: {
        locale: '工作台',
        title: '工作台',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    {
      path: 'platform',
      name: 'platform',
      component: () => import('@/views/work-platform/platform/index.vue'),
      meta: {
        locale: '工作台',
      },
    },
    // {
    //   path: 'services-overview-index',
    //   name: 'services-overview-index',
    //   component: () => import('@/views/services-overview/pages/overview.vue'),
    //   meta: {
    //     locale: '服务概览',
    //     title: '服务概览',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra','Rp', 'Rm','Rd'],
    //   },
    // },
    {
      path: 'data-statistics-index',
      name: 'data-statistics-index',
      component: () => import('@/views/work-platform/pages/data-statistics/index.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        // roles: ['Rf', 'Ra'],
        roles: ['Rf', 'Ra', 'Rp'],
      },
    },
    // 数据统计-各事业部菜单
    {
      path: 'data-statistics-unity-detail',
      name: 'data-statistics-unity-detail',
      component: () => import('@/views/work-platform/pages/data-statistics/pages/statistics-detail.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        // roles: ['Rd'],
        roles: ['Rd', 'Rp'],
      },
    },
    {
      path: 'data-statistics-detail',
      name: 'data-statistics-detail',
      component: () => import('@/views/work-platform/pages/data-statistics/pages/statistics-detail.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        // roles: ['Rf', 'Ra', 'Rd'],
        roles: ['Rf', 'Ra', 'Rd', 'Rp'],
        hideInMenu: true,
      },
    },
    // 数据统计-部门经理
    {
      path: 'statistics-manager',
      name: 'statistics-manager',
      component: () => import('@/views/work-platform/statistics-manager/index.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
      },
    },
    // 威胁情报
    {
      path: 'threat-intelligence-index',
      name: 'threat-intelligence-index',
      component: () => import('@/views/work-platform/pages/workbench/pages/threat-intelligence.vue'),
      meta: {
        locale: '威胁情报',
        title: '威胁情报',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 漏洞库
    {
      path: 'loophole-library-index',
      name: 'loophole-library-index',
      component: () => import('@/views/loophole-library/pages/library/index.vue'),
      meta: {
        locale: '漏洞库',
        title: '漏洞库',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    {
      path: 'internal-platform-index',
      name: 'internal-platform-index',
      component: () => import('@/views/internal-platform/index.vue'),
      meta: {
        locale: '内部平台',
        title: '内部平台',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'R0', 'Rd'],
      },
    },
    // 威胁情报 - IP
    {
      path: 'threaten-IP',
      name: 'threaten-IP',
      component: () => import('@/views/work-platform/pages/workbench/pages/threaten-IP.vue'),
      meta: {
        locale: '威胁情报-IP',
        title: '威胁情报-IP',
        requiresAuth: true,
        hideInMenu: true,
        props: true,
      },
    },
    // 威胁情报 - domain
    {
      path: 'threaten-domain',
      name: 'threaten-domain',
      component: () => import('@/views/work-platform/pages/workbench/pages/threaten-domain.vue'),
      meta: {
        locale: '威胁情报-domain',
        title: '威胁情报-domain',
        requiresAuth: true,
        hideInMenu: true,
        props: true,
      },
    },
    // 威胁情报 - MD5
    {
      path: 'threaten-MD5',
      name: 'threaten-MD5',
      component: () => import('@/views/work-platform/pages/workbench/pages/threaten-MD5.vue'),
      meta: {
        locale: '威胁情报-MD5',
        title: '威胁情报-MD5',
        requiresAuth: true,
        hideInMenu: true,
        props: true,
      },
    },
    // 威胁情报 - ipv6
    {
      path: 'threaten-ipv6',
      name: 'threaten-ipv6',
      component: () => import('@/views/work-platform/pages/workbench/pages/threaten-ipv6.vue'),
      meta: {
        locale: '威胁情报-ipv6',
        title: '威胁情报-ipv6',
        requiresAuth: true,
        hideInMenu: true,
        props: true,
      },
    },
    // 资产查询
    {
      path: 'exposure-query-index',
      name: 'exposure-query-index',
      component: () => import('@/views/work-platform/pages/workbench/pages/exposure-query/index.vue'),
      meta: {
        locale: '资产查询',
        title: '资产查询',
      },
    },
    // 资产查询-详情
    {
      path: 'exposure-query-detail',
      name: 'exposure-query-detail',
      component: () => import('@/views/work-platform/pages/workbench/pages/exposure-query/detail.vue'),
      meta: {
        locale: '资产查询-详情',
        title: '资产查询-详情',
        requiresAuth: true,
        hideInMenu: true,
        props: true,
      },
    },
    // 我的项目
    {
      path: 'project-index',
      name: 'project-index',
      component: () => import('@/views/my-project/index.vue'),
      meta: {
        locale: '我的项目',
        title: '我的项目',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 数据台账
    {
      path: 'data-ledger-index',
      name: 'data-ledger-index',
      component: () => import('@/views/data-ledger/index.vue'),
      meta: {
        locale: '数据台账',
        title: '数据台账',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 工具手册
    {
      path: 'util-book',
      name: 'util-book',
      component: () => import('@/views/mywork/util-book/index.vue'),
      meta: {
        locale: '工具手册',
        title: '工具手册',
      },
    },
    // 日志分析
    {
      path: 'log-analysis',
      name: 'log-analysis',
      component: () => import('@/views/mywork/log-analysis/index.vue'),
      meta: {
        locale: '日志分析',
        title: '日志分析',
      },
    },
    // 日志分析-应用日志分析服务
    {
      path: 'apply-log',
      name: 'apply-log',
      component: () => import('@/views/mywork/log-analysis/apply-log/index.vue'),
      meta: {
        locale: '应用日志分析服务',
        title: '应用日志分析服务',
        requiresAuth: true,
        hideInMenu: true,
        props: true,
      },
    },
    // 主机日志分析服务
    {
      path: 'host-log',
      name: 'host-log',
      component: () => import('@/views/mywork/log-analysis/host-log/index.vue'),
      meta: {
        locale: '主机日志分析服务',
        title: '主机日志分析服务',
        requiresAuth: true,
        hideInMenu: true,
        props: true,
      },
    },
    // 工单管理
    {
      path: 'worder-manage',
      name: 'worder-manage',
      component: () => import('@/views/mywork/worder-manage/index.vue'),
      meta: {
        locale: '工单管理',
        title: '工单管理',
        requiresAuth: true,
        props: true,
      },
    },
  ],
}
