export default {
  'login.form.title': '登录 Arco Design Pro',
  'login.form.userName.errMsg': '手机号不能为空',
  'login.form.password.errMsg': '验证码不能为空',
  'login.form.login.errMsg': '登录出错，轻刷新重试',
  'login.form.login.success': '欢迎使用',
  'login.form.userName.placeholder': '用户名：admin',
  'login.form.password.placeholder': '密码：admin',
  'login.form.rememberPassword': '记住密码',
  'login.form.forgetPassword': '忘记密码',
  'login.form.login': '登录',
  'login.form.register': '注册账号',
  // 'login.banner.slogan1': '开箱即用的高质量模板',
  'login.banner.slogan1': '客户资产和环境的全面把控',
  // 'login.banner.subSlogan1': '丰富的的页面模板，覆盖大多数典型业务场景',
  'login.banner.subSlogan1': '为客户提供软、硬件资产和网络环境安全状态的全面评估',
  // 'login.banner.slogan2': '内置了常见问题的解决方案',
  'login.banner.slogan2': '安全隐患闭环和安全情报共享联动',
  // 'login.banner.subSlogan2': '国际化，路由配置，状态管理应有尽有',
  'login.banner.subSlogan2': '专注安全服务过程闭环管理，提供资产和安全情报协同联动，提高预警防护能力',
  // 'login.banner.slogan3': '接入可视化增强工具AUX',
  'login.banner.slogan3': '全面的安全运营专家团队',
  // 'login.banner.subSlogan3': '实现灵活的区块式开发',
  'login.banner.subSlogan3': '多领域网络安全专家团队，保障用户安全需求，带给用户真正的安全价值 ',
}
