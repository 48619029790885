import { App } from 'vue'
import { use, registerMap } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { BarChart, LineChart, PieChart, RadarChart, MapChart, LinesChart, EffectScatterChart } from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  GraphicComponent,
  TitleComponent,
  VisualMapComponent,
} from 'echarts/components'
import world from '@/assets/geo/world.json'
import china from '@/assets/geo/china.json'
import Chart from './chart/index.vue'
import Breadcrumb from './breadcrumb/index.vue'
// import SvgIcon from './svg-icon/index.vue';

// Manually introduce ECharts modules to reduce packing size

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  PieChart,
  RadarChart,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  GraphicComponent,
  MapChart,
  TitleComponent,
  VisualMapComponent,
  LinesChart,
  EffectScatterChart,
])
registerMap('world', world)
registerMap('china', china)

export default {
  install(Vue: App) {
    Vue.component('Chart', Chart)
    Vue.component('Breadcrumb', Breadcrumb)
    // Vue.component('SvgIcon', SvgIcon);
  },
}
