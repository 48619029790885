import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'
import {
  oauthLogin as userLogin,
  // login as userLogin,
  // logout as userLogout,
  getUserInfo,
  LoginData,
  getUserInfoList,
} from '@/api/user'
import { setToken, clearToken, storeCookieToken, setExpiresToken } from '@/utils/auth'
import { removeRouteListener } from '@/utils/route-listener'
import { UserState } from './types'

const useUserStore = defineStore('user', {
  state: (): UserState => ({
    name: undefined,
    avatar: undefined,
    job: undefined,
    organization: undefined,
    location: undefined,
    email: undefined,
    introduction: undefined,
    personalWebsite: undefined,
    jobName: undefined,
    organizationName: undefined,
    locationName: undefined,
    phone: undefined,
    registrationDate: undefined,
    accountId: undefined,
    certification: undefined,
    role: '',
  }),

  getters: {
    userInfo(state: UserState): UserState {
      return { ...state }
    },
  },

  actions: {
    switchRoles(currentRole: any) {
      return new Promise((resolve) => {
        this.role = currentRole
        resolve(this.role)
      })
    },
    // Set user's information
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial)
    },

    // Reset user's information
    resetInfo() {
      this.$reset()
    },

    // Get user's information
    async info() {
      // const res = await getUserInfo();
      // this.setInfo(res.data);
    },

    // Login
    login(loginForm: LoginData) {
      return new Promise((resolve, reject) => {
        let result: any
        try {
          userLogin(loginForm).then(
            (res) => {
              setToken((res as any).data.data.access_token)
              storeCookieToken('AX_TOKEN', (res as any).data.data.access_token, (res as any).data.data.expires_in)
              setExpiresToken('ExpiresToken', (res as any).data.data.access_token, (res as any).data.data.expires_in)
              resolve(res.data.data)
            },
            () => {
              reject(result)
            }
          )
        } catch (err) {
          clearToken()
          reject(result)
        }
      })
    },
    // Logout
    async logout() {
      // await userLogout();
      this.resetInfo()
      clearToken()
      removeRouteListener()
      window.localStorage.clear()
    },
  },
})

export default useUserStore
