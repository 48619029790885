export default {
  path: 'mywork',
  name: 'mywork',
  component: () => import('@/views/mywork/index.vue'),
  meta: {
    locale: 'menu.mywork',
    requiresAuth: true,
    icon: 'icon-dashboard',
  },
  children: [
    // 服务申请
    {
      path: 'service-application',
      name: 'service-application',
      component: () => import('@/views/mywork/service-application/index.vue'),
      meta: {
        locale: 'menu.mywork.serviceApplication',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '服务申请',
      },
    },
    {
      path: 'workordermanage',
      name: 'workordermanage',
      component: () => import('@/views/mywork/work-order-manage/index.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '工单管理',
      },
    },
    {
      path: 'wom_ptest_add',
      name: 'wom_ptest_add',
      component: () => import('@/views/mywork/work-order-manage/services/penetration-test/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_ptest_edit',
      name: 'wom_ptest_edit',
      component: () => import('@/views/mywork/work-order-manage/services/penetration-test/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    {
      path: 'wom_ptest_change',
      name: 'wom_ptest_change',
      component: () => import('@/views/mywork/work-order-manage/services/penetration-test/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_wscanning_add',
      name: 'wom_wscanning_add',
      component: () => import('@/views/mywork/work-order-manage/services/website-scanning/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_wscanning_edit',
      name: 'wom_wscanning_edit',
      component: () => import('@/views/mywork/work-order-manage/services/website-scanning/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    {
      path: 'wom_wscanning_change',
      name: 'wom_wscanning_change',
      component: () => import('@/views/mywork/work-order-manage/services/website-scanning/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_sreinforce_add',
      name: 'wom_sreinforce_add',
      component: () => import('@/views/mywork/work-order-manage/services/safety-reinforce/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_sreinforce_edit',
      name: 'wom_sreinforce_edit',
      component: () => import('@/views/mywork/work-order-manage/services/safety-reinforce/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    {
      path: 'wom_sreinforce_change',
      name: 'wom_sreinforce_change',
      component: () => import('@/views/mywork/work-order-manage/services/safety-reinforce/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_codeaudit_add',
      name: 'wom_codeaudit_add',
      component: () => import('@/views/mywork/work-order-manage/services/code-audit/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_codeaudit_edit',
      name: 'wom_codeaudit_edit',
      component: () => import('@/views/mywork/work-order-manage/services/code-audit/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    {
      path: 'wom_codeaudit_change',
      name: 'wom_codeaudit_change',
      component: () => import('@/views/mywork/work-order-manage/services/code-audit/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_sinspection_add',
      name: 'wom_sinspection_add',
      component: () => import('@/views/mywork/work-order-manage/services/safety-inspection/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_sinspection_edit',
      name: 'wom_sinspection_edit',
      component: () => import('@/views/mywork/work-order-manage/services/safety-inspection/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_sinspection_change',
      name: 'wom_sinspection_change',
      component: () => import('@/views/mywork/work-order-manage/services/safety-inspection/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_ccheck_add',
      name: 'wom_ccheck_add',
      component: () => import('@/views/mywork/work-order-manage/services/configuration-check/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_ccheck_edit',
      name: 'wom_ccheck_edit',
      component: () => import('@/views/mywork/work-order-manage/services/configuration-check/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_ccheck_change',
      name: 'wom_ccheck_change',
      component: () => import('@/views/mywork/work-order-manage/services/configuration-check/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_hostscanning_add',
      name: 'wom_hostscanning_add',
      component: () => import('@/views/mywork/work-order-manage/services/host-scanning/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_hostscanning_edit',
      name: 'wom_hostscanning_edit',
      component: () => import('@/views/mywork/work-order-manage/services/host-scanning/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'wom_hostscanning_change',
      name: 'wom_hostscanning_change',
      component: () => import('@/views/mywork/work-order-manage/services/host-scanning/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'ordermonitoring',
      name: 'ordermonitoring',
      component: () => import('@/views/mywork/order-monitoring/index.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '订单监控',
      },
    },
    {
      path: 'om_serviceImplement_ptest',
      name: 'om_serviceImplement_ptest',
      component: () => import('@/views/mywork/order-monitoring/pages/components/ptest/om_serviceImplement_ptest.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'om_serviceCheckResult_ptest',
      name: 'om_serviceCheckResult_ptest',
      component: () => import('@/views/mywork/order-monitoring/pages/components/ptest/om_serviceCheckResult_ptest.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'om_appServiceCheckResult_ptest',
      name: 'om_appServiceCheckResult_ptest',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/ptest/om_appServiceCheckResult_ptest.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'om_serviceImplement_codeAudit',
      name: 'om_serviceImplement_codeAudit',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/codeAudit/om_serviceImplement_codeAudit.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'om_serviceCheckResult_codeAudit',
      name: 'om_serviceCheckResult_codeAudit',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/codeAudit/om_serviceCheckResult_codeAudit.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    {
      path: 'pro_orderIndex',
      name: 'pro_orderIndex',
      component: () => import('@/views/mywork/order-monitoring/pages/components/pro_orderIndex.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },

    // 订单维度 - 脆弱性检查 -服务实施情况
    {
      path: 'om_serviceImplement_configure',
      name: 'om_serviceImplement_configure',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/configure/om_serviceImplement_configure.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },

    // 脆弱性检查 - 服务结果
    {
      path: 'om_serviceCheckResult_configure',
      name: 'om_serviceCheckResult_configure',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/configure/om_serviceCheckResult_configure.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单维度 - 应用漏洞扫描服务 -服务实施情况
    {
      path: 'om_serviceImplement_scanning',
      name: 'om_serviceImplement_scanning',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/scanning/om_serviceImplement_scanning.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单维度 - 应用漏洞扫描服务 -服务结果
    {
      path: 'om_serviceCheckResult_scanning',
      name: 'om_serviceCheckResult_scanning',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/scanning/om_serviceCheckResult_scanning.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单维度 - 安全巡检 -服务实施情况
    {
      path: 'om_serviceImplement_inspection',
      name: 'om_serviceImplement_inspection',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/inspection/om_serviceImplement_inspection.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全巡检 - 服务结果
    {
      path: 'om_serviceCheckResult_inspection',
      name: 'om_serviceCheckResult_inspection',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/inspection/om_serviceCheckResult_inspection.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全巡检-详情
    {
      path: 'om_resultDetail_inspection',
      name: 'om_resultDetail_inspection',
      component: () =>
        import(
          '@/views/mywork/order-monitoring/pages/components/inspection/inspection_detail/om_resultDetail_inspection.vue'
        ),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全巡检(LINUX信息详情)-详情
    {
      path: 'om_linuxResultDetail_inspection',
      name: 'om_linuxResultDetail_inspection',
      component: () =>
        import(
          '@/views/mywork/order-monitoring/pages/components/inspection/inspection_detail/om_linuxResultDetail_inspection.vue'
        ),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单维度 - 主机扫描 -服务实施情况
    {
      path: 'om_serviceImplement_sreinforce',
      name: 'om_serviceImplement_sreinforce',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/host-scanning/om_serviceImplement_sreinforce.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单维度 - 主机扫描 -服务结果
    {
      path: 'om_serviceCheckResult_sreinforce',
      name: 'om_serviceCheckResult_sreinforce',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/host-scanning/om_serviceCheckResult_sreinforce.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单维度 - 安全整改加固服务 - 服务实施情况
    {
      path: 'om_serviceImplement_safety_reinforce',
      name: 'om_serviceImplement_safety_reinforce',
      component: () =>
        import(
          '@/views/mywork/order-monitoring/pages/components/safety-reinforce/om_serviceImplement_safety_reinforce.vue'
        ),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单维度 - 安全整改加固服务 - 加固策略
    {
      path: 'safety_reinforce_checkResult',
      name: 'safety_reinforce_checkResult',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/safety-reinforce/safety_reinforce_checkResult.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 服务申请 - 互联网暴露面监测
    {
      path: 'wom_internetDete_add',
      name: 'wom_internetDete_add',
      component: () => import('@/views/mywork/work-order-manage/services/internet-detection/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 服务修改 - 互联网暴露面监测
    {
      path: 'wom_internetDete_edit',
      name: 'wom_internetDete_edit',
      component: () => import('@/views/mywork/work-order-manage/services/internet-detection/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 服务申请 - 网站安全监控服务
    {
      path: 'wom_webMonitor_add',
      name: 'wom_webMonitor_add',
      component: () => import('@/views/mywork/work-order-manage/services/website-monitor/add.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 工单管理-网站安全监控服务编辑
    {
      path: 'wom_webMonitor_edit',
      name: 'wom_webMonitor_edit',
      component: () => import('@/views/mywork/work-order-manage/services/website-monitor/edit.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 网站安全监控服务-服务变更申请
    {
      path: 'wom_webMonitor_change',
      name: 'wom_webMonitor_change',
      component: () => import('@/views/mywork/work-order-manage/services/website-monitor/change.vue'),
      meta: {
        locale: 'menu.mywork.workordermanage',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 订单监控-网站安全监控服务
    {
      path: 'om_serviceImplement_website_monitor',
      name: 'om_serviceImplement_website_monitor',
      component: () =>
        import('@/views/mywork/order-monitoring/pages/components/website-monitor/om_serviceImplement_webMonitor.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 服务实施监控 --- 开始
    {
      path: 'service-apply-monitor',
      name: 'service-apply-monitor',
      component: () => import('@/views/service-apply-monitor/index.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '服务实施监控',
      },
    },
    // 安全巡检 - 服务结果
    {
      path: 'sam_serviceCheckResult_inspection',
      name: 'sam_serviceCheckResult_inspection',
      component: () => import('@/views/service-apply-monitor/pages/inspection/sam_serviceCheckResult_inspection.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全巡检-详情
    {
      path: 'sam_resultDetail_inspection',
      name: 'sam_resultDetail_inspection',
      component: () =>
        import('@/views/service-apply-monitor/pages/inspection/inspection_detail/sam_resultDetail_inspection.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全巡检(LINUX信息详情)-详情
    {
      path: 'sam_linuxResultDetail_inspection',
      name: 'sam_linuxResultDetail_inspection',
      component: () =>
        import('@/views/service-apply-monitor/pages/inspection/inspection_detail/sam_linuxResultDetail_inspection.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 脆弱性检查 - 服务结果
    {
      path: 'sam_serviceCheckResult_configure',
      name: 'sam_serviceCheckResult_configure',
      component: () => import('@/views/service-apply-monitor/pages/configure/sam_serviceCheckResult_configure.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 主机扫描 -服务结果
    {
      path: 'sam_serviceCheckResult_sreinforce',
      name: 'sam_serviceCheckResult_sreinforce',
      component: () =>
        import('@/views/service-apply-monitor/pages/host-scanning/sam_serviceCheckResult_sreinforce.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 应用漏洞扫描服务 -服务结果
    {
      path: 'sam_serviceCheckResult_scanning',
      name: 'sam_serviceCheckResult_scanning',
      component: () => import('@/views/service-apply-monitor/pages/scanning/sam_serviceCheckResult_scanning.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全整改加固服务 - 服务实施情况
    {
      path: 'sam_serviceImplement_safety_reinforce',
      name: 'sam_serviceImplement_safety_reinforce',
      component: () =>
        import('@/views/service-apply-monitor/pages/safety-reinforce/sam_serviceImplement_safety_reinforce.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 安全整改加固服务 - 加固策略
    {
      path: 'sam_safety_reinforce_checkResult',
      name: 'sam_safety_reinforce_checkResult',
      component: () =>
        import('@/views/service-apply-monitor/pages/safety-reinforce/sam_safety_reinforce_checkResult.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 远程渗透测试服务 - 第一层
    {
      path: 'sam_serviceImplement_ptest',
      name: 'sam_serviceImplement_ptest',
      component: () => import('@/views/service-apply-monitor/pages/ptest/sam_serviceImplement_ptest.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 远程渗透测试服务 - 第二层
    {
      path: 'sam_serviceCheckResult_ptest',
      name: 'sam_serviceCheckResult_ptest',
      component: () => import('@/views/service-apply-monitor/pages/ptest/sam_serviceCheckResult_ptest.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 远程渗透测试服务 - 第二层
    {
      path: 'sam_appServiceCheckResult_ptest',
      name: 'sam_appServiceCheckResult_ptest',
      component: () => import('@/views/service-apply-monitor/pages/ptest/sam_appServiceCheckResult_ptest.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 代码安全审计服务 - 第一层
    {
      path: 'sam_serviceImplement_codeAudit',
      name: 'sam_serviceImplement_codeAudit',
      component: () => import('@/views/service-apply-monitor/pages/codeAudit/sam_serviceImplement_codeAudit.vue'),
      meta: {
        locale: '服务实施监控',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 代码安全审计服务 - 第二层
    {
      path: 'sam_serviceCheckResult_codeAudit',
      name: 'sam_serviceCheckResult_codeAudit',
      component: () => import('@/views/service-apply-monitor/pages/codeAudit/sam_serviceCheckResult_codeAudit.vue'),
      meta: {
        locale: 'menu.mywork.ordermonitoring',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 服务实施监控 --- 结束
    {
      path: 'report-download',
      name: 'report-download',
      component: () => import('@/views/mywork/report-download/index.vue'),
      meta: {
        locale: '报告下载',
        title: '报告下载',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 实施过程记录列表
    // {
    //   path: 'implemen-record',
    //   name: 'implemen-record',
    //   component: () => import('@/views/mywork/implemen-record/index.vue'),
    //   meta: {
    //     locale: '实施过程记录',
    //     title: '实施过程记录',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //   },
    // },
    // 实施过程记录详情
    // {
    //   path: 'implemen-process-record',
    //   name: 'implemen-process-record',
    //   component: () => import('@/views/mywork/implemen-record/implemen-process-record.vue'),
    //   meta: {
    //     locale: '实施过程记录',
    //     title: '实施过程记录',
    //     requiresAuth: true,
    //     hideInMenu: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //   },
    // },
  ],
}
