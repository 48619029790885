/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { getTree } from '@/api/menu/index'
import type { Response } from '@/api/menu/index'
import { useAppStore, useUserStore } from '@/store'
import { useRouter, RouteRecordRaw, RouteRecordNormalized, RouteRecordName } from 'vue-router'
import type { Router } from 'vue-router'
import { isLogin } from '@/utils/auth'
import appRoutes, { routeList } from './modules'

// vite 会解析导入路径，所以当动态导入的是一个变量会找不到路径，通过import glob解决
// 逻辑上是：将目录下的所有文件静态引入，然后查找这个字典
// const modules = import.meta.glob('../views/**/*.vue');

// const router = useRouter();  // 这里总是返回undefined
function addRoutes(router: Router, routes: Response[]) {
  const rootRoute = {
    name: 'root',
    path: '/',
    component: () => import('@/layout/page-layout.vue'),
    children: [] as any[],
  }
  for (const route of routes) {
    // 一级菜单
    const currentRoute = routeList.find((el) => el.name === route.routeName) as RouteRecordRaw
    // 增加远程配置路由，但本地还没有路由时的异常兼容
    if (!currentRoute) continue
    // 本地二级菜单
    const locale_menu2 = currentRoute?.children?.filter((value) => value.meta?.hideInMenu) || []

    route.filePath = route.filePath?.replace('@/', '../')
    // console.log('动态路径', route.filePath, modules[route.filePath as string]);
    // 一级菜单动态路由
    const dynamicRoute = {
      path: `${route.routePath}`,
      name: route.routeName,
      // component: () => import(`${route.filePath}`),
      // component: () => import('@/views/work-platform/index.vue'),
      // component: modules[route.filePath as string],
      component: currentRoute.component,
      meta: {
        locale: route.name,
        icon: route.icon,
        requiresAuth: true,
        title: route.name,
      },
      children: [] as any[],
    }
    // 将服务端返回的二级菜单加入
    for (const child_2 of route.children || []) {
      child_2.filePath = child_2.filePath?.replace('@/', '../')
      // 本地当前二级菜单路由, 通过routeName查找获取组件路径
      const currentRoute2 = currentRoute?.children?.find((value) => value.name === child_2.routeName)
      if (!currentRoute2) continue
      dynamicRoute.children.push({
        path: `${child_2.routePath}`,
        name: child_2.routeName,
        // component: () => import(`${child_2.filePath}`),
        // component: modules[child_2.filePath as string],
        component: currentRoute2?.component,
        meta: {
          locale: child_2.name,
          title: child_2.name,
          requiresAuth: true,
        },
      })
    }
    // 拼接本地二次路由，通过name比对
    for (const locale2_item of locale_menu2) {
      // route.filePath = route.filePath?.replace('@', '../');
      dynamicRoute.children.push({
        path: `${locale2_item.path}`,
        name: locale2_item.name,
        component: locale2_item.component,
        meta: {
          locale: locale2_item.meta?.locale,
          title: locale2_item.meta?.title,
          requiresAuth: true,
          hideInMenu: true,
        },
      })
    }
    rootRoute.children.push(dynamicRoute)
  }
  // 添加本地一级菜单：通用
  for (const locale1 of appRoutes) {
    rootRoute.children.push(locale1)
  }
  router.addRoute(rootRoute)
  // 添加找不到时的匹配项
  router.addRoute({
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/not-found/index.vue'),
  })
}

/**
 * 动态添加路由
 */
export async function handleRouter(router: Router) {
  const appStore = useAppStore()
  if (appStore.asyncRoute) {
    return
  }
  // 登录后获取动态路由并且设置路由标志
  if (!isLogin()) return
  const result = await getTree()
  appStore.setAsyncRouter(true)
  appStore.setRouteList(result)
  if (result?.length) {
    addRoutes(router, result)
  } else {
    console.error('---获取动态路由接口返回的数组为空---')
  }
}
