export default {
  path: 'custom-deliver',
  name: 'custom-deliver',
  component: () => import('@/views/custom-deliver/index.vue'),
  meta: {
    locale: '客户交付平台',
    icon: 'icon-customer-service',
  },
  children: [
    // 数据概览
    {
      path: 'data-overview',
      name: 'data-overview',
      component: () => import('@/views/custom-deliver/data-overview/index.vue'),
      meta: {
        locale: '数据概率',
        title: '数据概览',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp'],
      },
    },
    // 项目概览
    {
      path: 'project-overview',
      name: 'project-overview',
      component: () => import('@/views/custom-deliver/project-overview/index.vue'),
      meta: {
        locale: '项目概览',
        title: '项目概览',
        requiresAuth: true,
        roles: ['Rd', 'Rp'],
      },
    },
    //资产概览
    // {
    //   path: 'asset-overview',
    //   name: 'asset-overview',
    //   component: () => import('@/views/custom-deliver/asset-overview/index.vue'),
    //   meta: {
    //     locale: '资产概览',
    //     title: '资产概览',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rd', 'Rp'],
    //   },
    // },
  ],
}
