export default {
  'menu.result.success': '成功页',
  'success.result.title': '提交成功',
  'success.result.subTitle': '表单提交成功！',
  'success.result.printResult': '打印结果',
  'success.result.projectList': '返回项目列表',
  'success.result.progress': '当前进度',
  'success.submitApplication': '提交申请',
  'success.leaderReview': '直属领导审核',
  'success.purchaseCertificate': '购买证书',
  'success.safetyTest': '安全测试',
  'success.launched': '正式上线',
  'success.waiting': '未开始',
  'success.processing': '进行中',
}
