export default {
  path: 'threaten-manage',
  name: 'threaten-manage',
  component: () => import('@/views/risk-manage/index.vue'),
  meta: {
    locale: '威胁管理',
    icon: 'icon-thunderbolt',
  },
  children: [
    {
      path: 'risk-manage-internet',
      name: 'risk-manage-internet',
      component: () => import('@/views/risk-manage/pages/internet-monitoring/index.vue'),
      meta: {
        locale: '互联网暴露面监测告警',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'website-monitor-alarm',
      name: 'website-monitor-alarm',
      component: () => import('@/views/risk-manage/pages/monitor-alarm/index.vue'),
      meta: {
        locale: '网站安全监控服务告警',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'safe-warn',
      name: 'safe-warn',
      component: () => import('@/views/risk-manage/safe-warn/index.vue'),
    },
    // 资产维护-云主机(详情)
    // {
    //   path: 'risk-manage-asset-detail',
    //   name: 'risk-manage-asset-detail',
    //   component: () => import('@/views/risk-manage/pages/asset-risk/components/virtual-machine/detail.vue'),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-物理主机(详情)
    // {
    //   path: 'risk-manage-asset-physicalHost-detail',
    //   name: 'risk-manage-asset-physicalHost-detail',
    //   component: () => import('@/views/risk-manage/pages/asset-risk/components/physical-host/detail.vue'),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产风险管理-网站资产(详情)
    // {
    //   path: 'risk-manage-asset-website-detail',
    //   name: 'risk-manage-asset-website-detail',
    //   component: () => import('@/views/risk-manage/pages/asset-risk/components/website-assets/detail.vue'),
    //   meta: {
    //     locale: '',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-业务系统(详情)
    // {
    //   path: 'risk-manage-asset-sys-detail',
    //   name: 'risk-manage-asset-sys-detail',
    //   component: () => import('@/views/risk-manage/pages/asset-risk/components/business-system/detail.vue'),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
  ],
}
