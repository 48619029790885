/* eslint-disable consistent-return */
import type { Router, LocationQueryRaw } from 'vue-router'
import NProgress from 'nprogress' // progress bar

import usePermission from '@/hooks/permission'
import { useUserStore, useAppStore } from '@/store'
import { isLogin } from '@/utils/auth'
import { handleRouter } from '@/router/menu'
import appRoutes from '../modules'
import { Message } from '@arco-design/web-vue'

/** 路由从path中获取name */
function getLastSegment(url) {
  const segments = url.split('/')
  if (segments.length > 0) {
    return segments[segments.length - 1]
  }
  return ''
}
export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    if (to.meta.title) {
      document.title = `安全运营综合服务平台 - ${to.meta.title}`
    }
    NProgress.start()
    const userStore = useUserStore()
    /* 判断有没有权限进入当前页：当前因为不以本地角色为准,所以不用判断 */
    async function crossroads() {
      const Permission = usePermission()
      if (Permission.accessRouter(to)) await next()
      else {
        const destination = Permission.findFirstPermissionRoute(appRoutes, userStore.role) || {
          name: 'notFound',
        }
        await next(destination)
      }
      NProgress.done()
    }
    // 没登陆就跳转到登录页
    if (isLogin()) {
      // 下面这段代码其实啥也没干
      try {
        crossroads()
      } catch (error) {
        next({
          name: 'login',
          query: {
            redirect: to.name,
            ...to.query,
          } as LocationQueryRaw,
        })
        NProgress.done()
      }
    } else {
      if (to.name === 'login') {
        next()
        NProgress.done()
        return
      }
      const name = getLastSegment(to.path)
      next({
        name: 'login',
        query: {
          redirect: name,
          ...to.query,
        } as LocationQueryRaw,
      })
      NProgress.done()
    }
  })
  router.beforeEach(async (to, from, next) => {
    await handleRouter(router)
    const appStore = useAppStore()
    const name = getLastSegment(to.path)
    // console.log(to, from, appStore.routeList, router.hasRoute(name))
    // 因为刷新后没有当前路由，需要等待动态路由配置后，再跳转。
    if (router.hasRoute(to.name)) {
      // 正常情况
      next()
    } else if (router.hasRoute(name)) {
      //第一次刷新路由时，to.name是空，需要从url路径中拿name，验证确实存在这个路由，并重新跳转
      next({
        name,
        query: {
          ...to.query,
        },
      })
    } else {
      if (appStore.routeList?.length) {
        Message.error('用户无当前菜单权限，自动跳转到首页')
        next({
          name: appStore.routeList[0].children[0].routeName,
        })
      } else {
        Message.error('用户暂未配置菜单，请联系管理员')
        next({
          name: 'login',
          query: {
            redirect: name,
            ...to.query,
          } as LocationQueryRaw,
        })
      }
    }
  })
  // 解决路由跳转时,页面不是在顶部
  router.afterEach((to, from, next) => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  })
}
