export default {
  path: 'statistics',
  name: 'statistics',
  component: () => import('@/views/statistics/index.vue'),
  meta: {
    locale: '报表统计',
    icon: 'icon-clock-circle',
  },
  children: [
    {
      path: 'data-statistics-index',
      name: 'data-statistics-index',
      component: () => import('@/views/work-platform/pages/data-statistics/index.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        // roles: ['Rf', 'Ra'],
        roles: ['Rf', 'Ra', 'Rp'],
      },
    },
    // 数据统计-各事业部菜单
    {
      path: 'data-statistics-unity-detail',
      name: 'data-statistics-unity-detail',
      component: () => import('@/views/work-platform/pages/data-statistics/pages/statistics-detail.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        // roles: ['Rd'],
        roles: ['Rd', 'Rp'],
      },
    },
    {
      path: 'data-statistics-detail',
      name: 'data-statistics-detail',
      component: () => import('@/views/work-platform/pages/data-statistics/pages/statistics-detail.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        // roles: ['Rf', 'Ra', 'Rd'],
        roles: ['Rf', 'Ra', 'Rd', 'Rp'],
        hideInMenu: true,
      },
    },
    // 数据统计-部门经理
    {
      path: 'statistics-manager',
      name: 'statistics-manager',
      component: () => import('@/views/work-platform/statistics-manager/index.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
      },
    },
    // 项目台账
    {
      path: 'project-index',
      name: 'project-index',
      component: () => import('@/views/my-project/index.vue'),
      meta: {
        locale: '项目台账',
        title: '项目台账',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 数据台账
    {
      path: 'data-ledger-index',
      name: 'data-ledger-index',
      component: () => import('@/views/data-ledger/index.vue'),
      meta: {
        locale: '数据台账',
        title: '数据台账',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 报表统计
    {
      path: 'chart-statistics-index',
      name: 'chart-statistics-index',
      component: () => import('@/views/chart-statistics/index.vue'),
      meta: {
        locale: '报表统计',
        title: '报表统计',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 报表详情
    {
      path: 'report-detail',
      name: 'report-detail',
      component: () => import('@/views/chart-statistics/report-detail.vue'),
      meta: {
        locale: '报表统计',
        title: '报表统计',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // 数据统计(项目经理/成员)
    {
      path: 'statistics-index',
      name: 'statistics-index',
      component: () => import('@/views/data-statistics/pages/member-chart/index.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 数据统计(超管)
    {
      path: 'statistics-super',
      name: 'statistics-super',
      component: () => import('@/views/data-statistics/pages/super-manage/index.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 漏洞态势
    {
      path: 'loophole-situation',
      name: 'loophole-situation',
      component: () => import('@/views/data-statistics/pages/loophole-situation/index.vue'),
      meta: {
        locale: '数据统计',
        title: '数据统计',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    // 服务平台新增3个大屏
    {
      path: 'service-status',
      name: 'service-status',
      component: () => import('@/views/large-screen/service-switch/index.vue'),
      meta: {
        locale: '服务态势',
      },
    },
    {
      path: 'history',
      name: 'history',
      component: () => import('@/views/large-screen/history/index.vue'),
      meta: {
        locale: '历史',
        hideInMenu: true,
      },
    },
    {
      path: 'vis-data',
      name: 'vis-data',
      component: () => import('@/views/large-screen/vis-data/index.vue'),
      meta: {
        locale: '可视化数据',
      },
    },
  ],
}
