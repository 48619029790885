export default {
  path: 'assetmanage',
  name: 'assetmanage',
  component: () => import('@/views/asset-manage/index.vue'),
  meta: {
    locale: 'menu.assetmanage',
    icon: 'icon-computer',
  },
  children: [
    {
      path: 'assetmanageoverview',
      name: 'assetmanageoverview',
      component: () => import('@/views/asset-manage/overview-new/index.vue'),
      meta: {
        locale: 'menu.assetmanage.overview',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '资产概览',
      },
    },
    {
      path: 'assetmanagemaintain',
      name: 'assetmanagemaintain',
      component: () => import('@/views/asset-manage/assetMaintain/index.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '资产维护',
      },
    },
    {
      path: 'assetgroupmanage',
      name: 'assetgroupmanage',
      component: () => import('@/views/asset-manage/assetGroupManage/index.vue'),
      meta: {
        locale: '资产组管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '资产组管理',
      },
    },
    {
      path: 'assetmanagefinger',
      name: 'assetmanagefinger',
      component: () => import('@/views/asset-manage/assetFinger/index.vue'),
      meta: {
        locale: '资产组件库',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        title: '资产组件库',
      },
    },
    // {
    //   path: 'assetmanagemaintain',
    //   name: 'assetmanagemaintain',
    //   component: () => import('@/views/asset-manage/maintain/index.vue'),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //      roles: ['Rf', 'Ra','Rp', 'Rm','Rd'],
    //     title:'资产维护'
    //   },
    // },
    // 资产维护-业务系统（添加）
    {
      path: 'asset_manage_maintain_sys_add',
      name: 'asset_manage_maintain_sys_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/business-system/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_sys_add',
    //   name: 'asset_manage_maintain_sys_add',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/business-system/add.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //   },
    // },
    // 资产维护-业务系统(编辑)
    {
      path: 'asset_manage_maintain_sys_edit',
      name: 'asset_manage_maintain_sys_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/business-system/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_sys_edit',
    //   name: 'asset_manage_maintain_sys_edit',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/business-system/edit.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-业务系统(详情)
    {
      path: 'asset_manage_maintain_sys_detail',
      name: 'asset_manage_maintain_sys_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/business-system/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 资产维护-云主机(添加)
    {
      path: 'asset_manage_maintain_asset_add',
      name: 'asset_manage_maintain_asset_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/virtual-machine/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_asset_add',
    //   name: 'asset_manage_maintain_asset_add',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/virtual-machine/add.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-云主机(编辑)
    {
      path: 'asset_manage_maintain_asset_edit',
      name: 'asset_manage_maintain_asset_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/virtual-machine/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_asset_edit',
    //   name: 'asset_manage_maintain_asset_edit',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/virtual-machine/edit.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-云主机(详情)
    {
      path: 'asset_manage_maintain_asset_detail',
      name: 'asset_manage_maintain_asset_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/virtual-machine/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 资产维护-网站资产(添加)
    {
      path: 'asset_manage_maintain_website_add',
      name: 'asset_manage_maintain_website_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/website-assets/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_website_add',
    //   name: 'asset_manage_maintain_website_add',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/website-assets/add.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-网站资产(编辑)
    {
      path: 'asset_manage_maintain_website_edit',
      name: 'asset_manage_maintain_website_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/website-assets/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_website_edit',
    //   name: 'asset_manage_maintain_website_edit',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/website-assets/edit.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-网站资产(详情)
    {
      path: 'asset_manage_maintain_website_detail',
      name: 'asset_manage_maintain_website_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/website-assets/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 资产维护-移动客户端(添加)
    {
      path: 'asset_manage_maintain_moveClient_add',
      name: 'asset_manage_maintain_moveClient_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/move-client/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_moveClient_add',
    //   name: 'asset_manage_maintain_moveClient_add',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/move-client/add.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-移动客户端(编辑)
    {
      path: 'asset_manage_maintain_moveClient_edit',
      name: 'asset_manage_maintain_moveCliente_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/move-client/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_moveClient_edit',
    //   name: 'asset_manage_maintain_moveCliente_edit',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/move-client/edit.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-移动客户端(详情)
    {
      path: 'asset_manage_maintain_moveClient_detail',
      name: 'asset_manage_maintain_moveClient_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/move-client/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 资产维护-物理主机(添加)
    {
      path: 'asset_manage_maintain_physical_add',
      name: 'asset_manage_maintain_physical_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/physical-host/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_physical_add',
    //   name: 'asset_manage_maintain_physical_add',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/physical-host/add.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-物理主机(编辑)
    {
      path: 'asset_manage_maintain_physical_edit',
      name: 'asset_manage_maintain_physical_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/physical-host/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_physical_edit',
    //   name: 'asset_manage_maintain_physical_edit',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/physical-host/edit.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-物理主机(详情)
    {
      path: 'asset_manage_maintain_physicalHost_detail',
      name: 'asset_manage_maintain_physicalHost_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/physical-host/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 网络设备维护（添加）
    {
      path: 'asset_manage_maintain_net_equipment_add',
      name: 'asset_manage_maintain_net_equipment_add',
      component: () => import('@/views/asset-manage/maintain/pages/components/network-equipment/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 网络设备维护（编辑）
    {
      path: 'asset_manage_maintain_net_equipment_edit',
      name: 'asset_manage_maintain_net_equipment_edit',
      component: () => import('@/views/asset-manage/maintain/pages/components/network-equipment/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 资产维护-网络设备(详情)
    {
      path: 'asset_manage_maintain_networkEquipment_detail',
      name: 'asset_manage_maintain_networkEquipment_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/network-equipment/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 安全设备维护（添加）
    {
      path: 'asset_manage_maintain_safe_equipment_add',
      name: 'asset_manage_maintain_safe_equipment_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/safe-equipment/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_safe_equipment_add',
    //   name: 'asset_manage_maintain_safe_equipment_add',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/safe-equipment/add.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 安全设备维护（编辑）
    {
      path: 'asset_manage_maintain_safe_equipment_edit',
      name: 'asset_manage_maintain_safe_equipment_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/safe-equipment/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_safe_equipment_edit',
    //   name: 'asset_manage_maintain_safe_equipment_edit',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/safe-equipment/edit.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 资产维护-安全设备(详情)
    {
      path: 'asset_manage_maintain_safeEquipment_detail',
      name: 'asset_manage_maintain_safeEquipment_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/safe-equipment/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 办公出口维护（添加）
    {
      path: 'asset_manage_maintain_work_exit_add',
      name: 'asset_manage_maintain_work_exit_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/work-exit/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_work_exit_add',
    //   name: 'asset_manage_maintain_work_exit_add',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/work-exit/add.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 办公出口维护（编辑）
    {
      path: 'asset_manage_maintain_work_exit_edit',
      name: 'asset_manage_maintain_work_exit_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/work-exit/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // {
    //   path: 'asset_manage_maintain_work_exit_edit',
    //   name: 'asset_manage_maintain_work_exit_edit',
    //   component: () =>
    //     import(
    //       '@/views/asset-manage/maintain/pages/components/work-exit/edit.vue'
    //     ),
    //   meta: {
    //     locale: 'menu.assetmanage.maintain',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
    //     hideInMenu: true,
    //     props: true,
    //   },
    // },
    // 办公出口（详情）
    {
      path: 'asset_manage_maintain_workExit_detail',
      name: 'asset_manage_maintain_workExit_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/work-exit/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 其他设备添加
    {
      path: 'asset_manage_maintain_other_device_add',
      name: 'asset_manage_maintain_other_device_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/other-device/add.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 其他设备编辑
    {
      path: 'asset_manage_maintain_other_device_edit',
      name: 'asset_manage_maintain_other_device_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/other-device/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 其他设备详情
    {
      path: 'asset_manage_maintain_other_device_detail',
      name: 'asset_manage_maintain_other_device_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/other-device/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.maintain',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 拓扑图新增
    {
      path: 'topology_map_add',
      name: 'topology_map_add',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/topology-map/add.vue'),
      meta: {
        locale: 'menu.assetmanage.topology',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    //拓扑图编辑
    {
      path: 'topology_map_edit',
      name: 'topology_map_edit',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/topology-map/edit.vue'),
      meta: {
        locale: 'menu.assetmanage.topology',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    //拓扑图详情
    {
      path: 'topology_map_detail',
      name: 'topology_map_detail',
      component: () => import('@/views/asset-manage/assetMaintain/pages/components/topology-map/detail.vue'),
      meta: {
        locale: 'menu.assetmanage.topology',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },    
    },
  ],
}

