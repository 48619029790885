import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'

const isLogin = () => {
  const jwt = localStorage.getItem('token')
  if (!jwt) return false
  const payload = jwt_decode(jwt)
  if (payload.exp * 1000 - Date.now() < 10000) {
    return false
  }
  return true
}

const getToken = () => {
  return localStorage.getItem('token')
}

const setToken = (token: string) => {
  localStorage.setItem('token', token)
  localStorage.setItem('Authorization', `Bearer ${token}`)
}

/*
  为避免过期时间因时区少8小时，固storeCookieToken、setExpiresToken增加28800秒
*/
const storeCookieToken = (name: string, value: string, expiresTime: number) => {
  const expdate = new Date()
  const realTime = expiresTime * 1000
  expdate.setTime(expdate.getTime() + realTime)
  document.cookie = `${name}=${value};expires=${(expdate as any).toGMTString()};path=/;domain=.cmss.axtx.com.cn`
}
const clearToken = () => {
  localStorage.removeItem('token')
}
// cookie保存的天数

// export const TOKEN_KEY = 'NOW_TOKEN'

const setExpiresToken = (name: string, value: string, expiresTime: number) => {
  const expdate = new Date()
  const realTime = expiresTime * 1000
  expdate.setTime(expdate.getTime() + realTime)
  document.cookie = `${name}=${value};expires=${(expdate as any).toGMTString()}`
}

const getExpiresToken = (TOKEN_KEY: string) => {
  const token = Cookies.get(TOKEN_KEY)
  let temp = ''
  if (token) {
    temp = token
  } else {
    temp = ''
  }
  return temp
  // else return false
}

const delExpiresToken = (TOKEN_KEY: string) => {
  Cookies.remove(TOKEN_KEY)
}

export { isLogin, getToken, setToken, storeCookieToken, setExpiresToken, getExpiresToken, delExpiresToken, clearToken }
