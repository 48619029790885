export default {
  path: 'safety-notice-manage',
  name: 'safety-notice-manage',
  component: () => import('@/views/safety-notice-manage/index.vue'),
  meta: {
    locale: '通告管理',
    icon: 'icon-command',
  },
  children: [
    // {
    //   path: 'safety-notice-manage-index',
    //   name: 'safety-notice-manage-index',
    //   component: () => import('@/views/safety-notice-manage/pages/notice-manage/index.vue'),
    //   meta: {
    //     locale: '安全通告',
    //     title:'安全通告',
    //     requiresAuth: true,
    //     roles: ['Rf', 'Ra','Rp', 'Rm','Rd'],
    //   },
    // },
    {
      path: 'safety-notice-risk',
      name: 'safety-notice-risk',
      component: () => import('@/views/safety-notice-manage/pages/risk-page.vue'),
      meta: {
        locale: '风险通告',
        title: '风险通告',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    {
      path: 'safety-notice-safe',
      name: 'safety-notice-safe',
      component: () => import('@/views/safety-notice-manage/pages/safe-page.vue'),
      meta: {
        locale: '安全通告',
        title: '安全通告',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
    {
      path: 'system-notice',
      name: 'system-notice',
      component: () => import('@/views/safety-notice-manage/pages/system-page.vue'),
      meta: {
        locale: '系统公告',
        title: '系统公告',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
      },
    },
  ],
}
