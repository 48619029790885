export default {
  path: 'large-screen',
  name: 'large-screen',
  component: () => import('@/views/large-screen/index.vue'),
  meta: {
    locale: '大屏',
    icon: 'icon-bar-chart',
  },
  children: [
    {
      path: 'service-status',
      name: 'service-status',
      component: () => import('@/views/large-screen/service-status/index.vue'),
      meta: {
        locale: '服务态势',
      },
    },
    {
      path: 'history',
      name: 'history',
      component: () => import('@/views/large-screen/history/index.vue'),
      meta: {
        locale: '历史',
      },
    },
    {
      path: 'vis-data',
      name: 'vis-data',
      component: () => import('@/views/large-screen/vis-data/index.vue'),
      meta: {
        locale: '可视化数据',
      },
    },
  ],
}
