export default {
  path: 'customer-manage',
  name: 'customer-manage',
  component: () => import('@/views/customer-manage/index.vue'),
  meta: {
    locale: '客户管理',
    icon: 'icon-user-group',
  },
  children: [
    {
      path: 'service-company',
      name: 'service-company',
      component: () => import('@/views/customer-manage/service-company/index.vue'),
      meta: {
        locale: '服务单位',
      },
    },
    {
      path: 'customer-staff',
      name: 'customer-staff',
      component: () => import('@/views/customer-manage/staff-manage/index.vue'),
      meta: {
        locale: '客户人员管理',
      },
    },
    // 新增单位
    {
      path: 'add_service_unit',
      name: 'add_service_unit',
      component: () => import('@/views/customer-manage/service-company/pages/add-service-unit.vue'),
      meta: {
        locale: '新增服务单位',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 编辑单位
    {
      path: 'edit_service_unit',
      name: 'edit_service_unit',
      component: () => import('@/views/customer-manage/service-company/pages/edit-service-unit.vue'),
      meta: {
        locale: '编辑服务单位',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 服务单位详情
    {
      path: 'service_unit_detail',
      name: 'service_unit_detail',
      component: () => import('@/views/customer-manage/service-company/pages/service-unit-detail.vue'),
      meta: {
        locale: '服务单位详情',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    // 服务单位详情
    {
      path: 'safe_event_notice',
      name: 'safe_event_notice',
      component: () => import('@/views/customer-manage/service-company/pages/safe-event-notice.vue'),
      meta: {
        locale: '安全事件通告',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
  ],
}
