// import ServicesOverview from './services-overview'; // 服务概览
import WorkPlatform from './work-platform' // 工作台
import Statistics from './statistics' // 数据统计
import ServiceManage from './service-manage' // 服务管理
import Mywork from './mywork'
import AssetManage from './asset-manage'
import RiskManage from './risk-manage' // 风险管理
import ThreatenManage from './threaten-manage' // 威胁管理
import websiteMonitor from './website-monitor' // 网站安全监控服务
import internetDetection from './internet-detection' // 互联网暴露面监测
import ProjectManage from './project-manage' // 项目管理
import ThreatPercept from './threat-percept' // 威胁感知
import DataZone from './data-zone' // 数据专区
import SafetyNoticeManage from './safety-notice-manage' // 通告管理
import InspectManage from './inspect-manage' // 迎检管理
import AccountManage from './account-manage' // 系统管理
import CustomerManage from './customer-manage' // 客户管理
import LargeScreen from './large-screen'
import CustomDeliver from './custom-deliver' // 客户交付平台
// import safetyHazardHandle from './safety-hazard-handle'; // 隐患管理
// import SecuritySituation from './security-situation';  // 综合安全态势
// import AttackWarning from './attack-warning';  // 安全告警
// import KeyPointCenter from './key-point';  // 重保中心
// import InternalPlatform from './internal-platform'; // 内部平台
// import PoliciesRules from './policiesRules'; // 政策法规
// import LoopholePlatform from './loophole-platform'; // 漏洞平台
// import ExamSystem from './exam-system'; // 考试系统
// import SecrecyExam from './secrecy-exam'; // 保密考试
import Result from './result'
import Exception from './error'

/**
 * 比对name，拿到不是二级菜单的静态路由加入
 */
export const routeList = [
  // ServicesOverview, // 服务概览
  WorkPlatform, // 工作台
  Statistics, // 数据统计
  ServiceManage, // 服务管理
  Mywork,
  AssetManage, // 资产管理
  RiskManage, // 风险管理
  ThreatenManage, // 威胁管理
  websiteMonitor, // 网站安全监控服务
  internetDetection, // 互联网暴露面监测
  ProjectManage, // 项目管理
  ThreatPercept, // 威胁感知
  DataZone, // 数据专区
  // safetyHazardHandle, // 隐患管理
  SafetyNoticeManage,
  // SecuritySituation,
  // AttackWarning,
  // KeyPointCenter,
  // InternalPlatform, // 内部平台
  // PoliciesRules, // 政策法规
  // LoopholePlatform, // 漏洞平台
  // ExamSystem, // 考试系统
  // SecrecyExam, // 保密考试
  InspectManage, // 迎检管理
  AccountManage, // 系统管理
  CustomerManage, // 客户管理
  LargeScreen, // 大屏
  CustomDeliver, // 客户交付平台
  // Result,
  // Exception,
]

export default [Result, Exception]
