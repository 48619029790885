export default {
  path: 'website-monitor',
  name: 'website-monitor',
  component: () => import('@/views/website-monitor/index.vue'),
  meta: {
    locale: '网站安全监控服务',
    icon: 'icon-find-replace',
  },
  children: [
    {
      path: 'monitor-task',
      name: 'monitor-task',
      component: () => import('@/views/website-monitor/pages/monitor-task/index.vue'),
      meta: {
        locale: '监控任务',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'alarm-list',
      name: 'alarm-list',
      component: () => import('@/views/website-monitor/pages/alarm-list/index.vue'),
      meta: {
        locale: '告警列表',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
    {
      path: 'alarm_list_detail',
      name: 'alarm_list_detail',
      component: () => import('@/views/website-monitor/pages/alarm-list/pages/alarm-detail.vue'),
      meta: {
        locale: '告警事件管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
        hideInMenu: true,
        props: true,
      },
    },
    {
      path: 'alarm-event-manage',
      name: 'alarm-event-manage',
      component: () => import('@/views/website-monitor/pages/alarm-event/index.vue'),
      meta: {
        locale: '告警事件管理',
        requiresAuth: true,
        roles: ['Rf', 'Ra', 'Rp', 'Rm', 'Rc', 'Rd'],
      },
    },
  ],
}
