import localeMessageBox from '@/components/message-box/locale/zh-CN'
import localeLogin from '@/views/login/locale/zh-CN'

// 任务管理
import localeWorkOrderManage from '@/views/mywork/work-order-manage/locale/zh-CN'
import localeOrderMonitoring from '@/views/mywork/order-monitoring/locale/zh-CN'

// 资产管理
import localeAssetManageOverview from '@/views/asset-manage/overview/locale/zh-CN'
import localeAssetManageMaintain from '@/views/asset-manage/maintain/locale/zh-CN'

// 隐患处理
// import localeWebsiteHiddenDanger from '@/views/hidden-danger/website/locale/zh-CN';
// import localeHostHiddenDanger from '@/views/hidden-danger/host/locale/zh-CN';

// 综合安全态势
import localeSSituationDashboard from '@/views/security-situation/dashboard/locale/zh-CN'

// 攻击告警
import localeAWarningAAnalysis from '@/views/attack-warning/alarm-analysis/locale/zh-CN'
import localeAWarningADetails from '@/views/attack-warning/alarm-details/locale/zh-CN'
import localeAWarningAThreat from '@/views/attack-warning/threat-analysis/locale/zh-CN'
import localeAWarningATime from '@/views/attack-warning/time-analysis/locale/zh-CN'
import localeAWarningAToday from '@/views/attack-warning/today-attack/locale/zh-CN'

// 重保中心
import localeKeyPointOverview from '@/views/key-point/overview/locale/zh-CN'
import localeKeyPointAttack from '@/views/key-point/attack/locale/zh-CN'

import localeSuccess from '@/views/result/success/locale/zh-CN'
import localeError from '@/views/result/error/locale/zh-CN'
import localeFresh from '@/views/result/refresh/locale/zh-CN'

import locale403 from '@/views/exception/403/locale/zh-CN'
import locale404 from '@/views/exception/404/locale/zh-CN'
import locale500 from '@/views/exception/500/locale/zh-CN'

import localeUserInfo from '@/views/user/info/locale/zh-CN'
import localeUserSetting from '@/views/user/setting/locale/zh-CN'

import localeSettings from './zh-CN/settings'

export default {
  'menu.mywork': '任务管理',
  'menu.assetmanage': '资产管理',
  // 'menu.hiddendanger':'隐患处理',
  'menu.result': '结果页',
  'menu.security-situation': '综合安全态势',
  'menu.attack-warning': '攻击告警',
  'menu.key-point': '重保中心',
  'menu.exception': '异常页',
  'menu.user': '个人中心',
  'navbar.docs': '文档中心',
  'navbar.action.locale': '切换为中文',
  ...localeSettings,
  ...localeMessageBox,
  ...localeLogin,
  ...localeWorkOrderManage,
  ...localeOrderMonitoring,
  ...localeAssetManageOverview,
  ...localeAssetManageMaintain,
  // ...localeWebsiteHiddenDanger,  // 网站隐患
  // ...localeHostHiddenDanger,    // 主机隐患
  ...localeSSituationDashboard,
  ...localeAWarningAAnalysis,
  ...localeAWarningADetails,
  ...localeAWarningAThreat,
  ...localeAWarningATime,
  ...localeAWarningAToday,
  ...localeKeyPointOverview,
  ...localeKeyPointAttack,
  ...localeSuccess,
  ...localeError,
  ...localeFresh,
  ...locale403,
  ...locale404,
  ...locale500,
  ...localeUserInfo,
  ...localeUserSetting,
}
