import type { Router } from 'vue-router'
import { setRouteEmitter } from '@/utils/route-listener'
// import { useUserStore } from '@/store';
import setupPermissionGuard from './permission'

function setupPageGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    setRouteEmitter(to)
    next()
  })
}

export default async function createRouteGuard(router: Router) {
  setupPageGuard(router)
  setupPermissionGuard(router)
}
