import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { useUserStore } from '@/store'

export default function usePermission() {
  const userStore = useUserStore()
  const storageRole = localStorage.getItem('userRole') || ''
  return {
    accessRouter(route: RouteLocationNormalized | RouteRecordRaw) {
      // 判断对这个页面有无访问权限
      return true
      // return (
      //   !route.meta?.requiresAuth ||
      //   !route.meta?.roles ||
      //   route.meta?.roles?.includes('*') ||
      //   route.meta?.roles?.includes(storageRole)
      // );
    },
    findFirstPermissionRoute(_routers: any, role = 'manager') {
      const cloneRouters = [..._routers]
      while (cloneRouters.length) {
        const firstElement = cloneRouters.shift()
        if (
          firstElement?.meta?.roles?.find((el: string[]) => {
            return el.includes('*') || el.includes(role)
          })
        )
          return { name: firstElement.name }
        if (firstElement?.children) {
          cloneRouters.push(...firstElement.children)
        }
      }
      return null
    },
    // You can add any rules you want
  }
}
