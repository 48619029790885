import axios from 'axios'
import { getExpiresToken } from '@/utils/auth'
import { Message, Modal } from '@arco-design/web-vue'
import { getData } from '@/api/common/index'
import { baseSuffix, aptSuffix } from '@/api/interceptor'
import { getUserInfoList } from '@/api/user'

const headers = {
  Authorization: `Bearer ${getExpiresToken('ExpiresToken')}`,
}

export interface Response {
  /**
   * 子菜单
   */
  children?: Response[]
  /**
   * 文件路径
   */
  filePath?: string
  /**
   * 图标
   */
  icon?: string
  /**
   * 菜单ID
   */
  menuId?: string
  /**
   * 菜单名称
   */
  name?: string
  /**
   * 按钮权限
   */
  perms?: string[]
  /**
   * 路由名称
   */
  routeName?: string
  /**
   * 路由路径
   */
  routePath?: string
  /**
   * 排序
   */
  sort?: number
}

export async function refreshUserInfo() {
  // 重新获取菜单树的时候，刷新用户信息缓存
  const result = await getUserInfoList()
  const userRes = result.data.data
  if (userRes?.id) {
    localStorage.setItem('currentUserId', userRes.id)
  }
  localStorage.setItem('UserInfo', JSON.stringify(userRes))
  if (!userRes?.orgVoList?.length) {
    // 存储当前的单位列表
    localStorage.setItem('orgVoList', [])
    // 存储当前的单位id
    localStorage.setItem('currentCompanyId', '-1')
    localStorage.setItem('currentCompanyName', '')
  } else {
    // 存储当前的单位列表
    localStorage.setItem('orgVoList', JSON.stringify(userRes.orgVoList))
    const currentCompanyId = localStorage.getItem('currentCompanyId')
    if (currentCompanyId) {
      const findEle = userRes.orgVoList.find((element) => {
        return element.id == currentCompanyId
      })
      if (!findEle) {
        // 当前单位不在列表内就设为第一个
        localStorage.setItem('currentCompanyId', userRes.orgVoList[0]?.id)
        localStorage.setItem('currentCompanyName', userRes.orgVoList[0]?.orgName)
      }
    } else {
      const currentCompanyId = localStorage.getItem('currentCompanyId')
      if (currentCompanyId) {
        const findEle = userRes.orgVoList.find((element) => {
          return element.id == currentCompanyId
        })
        if (!findEle) {
          // 当前单位不在列表内就设为第一个
          localStorage.setItem('currentCompanyId', userRes.orgVoList[0]?.id)
          localStorage.setItem('currentCompanyName', userRes.orgVoList[0]?.orgName)
        }
      } else {
        localStorage.setItem('currentCompanyId', userRes.orgVoList[0]?.id)
        localStorage.setItem('currentCompanyName', userRes.orgVoList[0]?.orgName)
      }
    }
  }
}

export async function getTree(): Promise<Response[]> {
  await refreshUserInfo()
  const res = await axios.get(`/cas/menu/tree`, {
    headers,
    hasHeaders: true, // 默认会在全局统一添加请求头
  } as any)
  return res.data.data
}
